import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js"

// Connects to data-controller="staging-poller"
// Gets a ProcessingCount via a JSON polling request.
// When count is 0, it requests a TurboStream response to get the staged videos.
// When the StagedVideos section is empty, removes the StagedVideos section.
export default class extends Controller {
    static values = {
        url: String,

        pollingInterval: {type: Number, default: 15000}, // 15 seconds

        stagedCount: {type: Number, default: 0},
    }

    static targets = [
        'stagedItem', 'editForm', 'stagingSection',
        'processing', 'processingCount',
        'staged', 'stagedCount'
    ]

    connect() {
        // console.debug(`staging-poller: connect - pollingInterval: ${this.pollingIntervalValue}`)

        // Allegedly, this will prevent errors when leaving the page in the middle of a poll
        document.addEventListener('turbo:before-cache', this.disconnect.bind(this))
        this.poll().then() // Make an immediate poll request on connect
    }

    disconnect() {
        document.removeEventListener('turbo:before-cache', this.disconnect.bind(this))
        clearInterval(this._timeoutID)
    }

    editFormTargetConnected() {
        // console.debug('staging-poller: editFormTargetConnected')
        this.stagingSectionTarget.classList.add('hidden')
    }

    editFormTargetDisconnected() {
        // console.debug('staging-poller: editFormTargetDisconnected')
        this.stagingSectionTarget.classList.remove('hidden')
    }

    stagedItemTargetConnected() {
        // console.debug('staging-poller: stagedItemTargetConnected')
        this.element.classList.remove('hidden')
        this.processingTarget.classList.add('hidden')
        this.stagedTarget.classList.remove('hidden')
        this.stagedCountValue = this.stagedCountValue + 1

        this.updateStagedCount()
    }

    stagedItemTargetDisconnected() {
        // console.debug('staging-poller: stagedItemTargetDisconnected')
        this.stagedCountValue = this.stagedCountValue - 1

        this.updateStagedCount()
    }

    nextPoll() {
        clearTimeout(this._timeoutID) // just in-case we've got an active timer
        this._timeoutID = setTimeout(this.poll.bind(this), this.pollingIntervalValue)
    }

    updateProcessingCount() {
        if (this.processingCountValue === 1) {
            this.processingCountTarget.textContent = `${this.processingCountValue} video`
        } else {
            this.processingCountTarget.textContent = `${this.processingCountValue} videos`
        }

        this.processingTarget.classList.remove('hidden')
    }

    updateStagedCount() {
        if (this.stagedCountValue === 1) {
            this.stagedCountTarget.textContent = `${this.stagedCountValue} video`
        } else {
            this.stagedCountTarget.textContent = `${this.stagedCountValue} videos`
        }

        if (this.stagedCountValue === 0) {
            const element = document.getElementById('staging-anchor')

            if (element) {
                element.parentNode.removeChild(element)
            }
        }
    }

    // poll()
    // Using request.js, requests a poll event passing query parameters to help the server
    // determine what data to return.
    // First poll for a Processing count via JSON.
    // Keep polling until the Processing count is 0.
    // Then request a TurboStream response to get the staged videos.
    async poll() {
        // console.log('staging-poller: poll ', this.activePollingIntervalValue)

        clearTimeout(this._timeoutID) // just in-case we've got an active timer

        const response = await get(this.urlValue, {responseKind: 'json'})

        try {
            if (response.ok) {
                const obj = await response.json
                this.processingCountValue = obj.processingCount
                // console.debug("staging-poller: ", obj)

                if (this.processingCountValue > 0) {
                    this.updateProcessingCount()
                    this.nextPoll()
                } else if (obj.stagedReady === false) {
                    // This is just buying time for the EmailBatchCompleteJob to finish
                    this.nextPoll()
                } else {
                    await this.requestStagedVideos()
                }
            }
        } catch (error) {
            console.error('staging-poller: poll failed', error)
        }
    }

    async requestStagedVideos() {
        try {
            const response = await get(this.urlValue, {responseKind: 'turbo-stream'})

            if (!response.ok) {
                console.error('staging-poller: requestStagedVideos failed', response)
                this.updateStagedCount()
            }
        } catch (error) {
            console.error('staging-poller: poll failed', error)
        }
    }
}
