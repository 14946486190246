import {StreamActions} from "@hotwired/turbo"

const linkDisable = ['!text-m-gray-400', '!cursor-not-allowed', '!pointer-events-none']
const linkAsButtonDisable = ['!bg-m-gray-100', '!text-m-gray-400', '!cursor-not-allowed', '!pointer-events-none']

// This is a turbo-stream event
// If we need more than one, we should pull it out of here and move it to a separate file
StreamActions.toggleDisableable = function () {
    Array.from(document.getElementsByClassName('toggle-disableable')).forEach((element) => {
        if (element.dataset.toggleDisableWasDisabledValue === 'true') {
            return
        }

        if (element.hasAttribute('disabled')) {
            element.removeAttribute('disabled')
        } else {
            element.setAttribute('disabled', 'true')
        }

        // special handling for SVGs and IMGs, where we hide them instead of disabling
        // them since they don't disable (like buttons)
        if (element.nodeName === 'SVG' || element.nodeName === 'IMG' || element.nodeName === 'DIV') {
            if (element.hasAttribute('disabled')) {
                element.classList.add('hidden')
            } else {
                element.classList.remove('hidden')
            }
        }

        if (element.nodeName === 'A') {
            if (element.hasAttribute('disabled')) {
                if (element.classList.contains('link-as-button')) {
                    element.classList.add(...linkAsButtonDisable)
                } else {
                    element.classList.add(...linkDisable)
                }
            } else {
                if (element.classList.contains('link-as-button')) {
                    element.classList.remove(...linkAsButtonDisable)
                } else {
                    element.classList.remove(...linkDisable)
                }
            }
        }
    })
}

StreamActions.enableDisableable = function () {
    Array.from(document.getElementsByClassName('toggle-disableable')).forEach((element) => {
        if (element.dataset.toggleDisableWasDisabledValue === 'true') {
            return
        }

        // special handling for SVGs and IMGs, where we unhide them instead of disabling
        // them since they don't disable (like buttons)
        if (element.nodeName === 'SVG' || element.nodeName === 'IMG' || element.nodeName === 'DIV') {
            if (element.hasAttribute('disabled')) {
                element.classList.remove('hidden')
            }
        }

        if (element.nodeName === 'A') {
            if (element.classList.contains('link-as-button')) {
                element.classList.remove(...linkAsButtonDisable)
            } else {
                element.classList.remove(...linkDisable)
            }
        }

        element.removeAttribute('disabled')
    })
}

StreamActions.disableDisableable = function () {
    Array.from(document.getElementsByClassName('toggle-disableable')).forEach((element) => {
        if (element.dataset.toggleDisableWasDisabledValue === 'true') {
            return
        }

        element.setAttribute('disabled', 'true')

        // special handling for SVGs and IMGs, where we hide them instead of disabling
        // them since they don't disable (like buttons)
        if (element.nodeName === 'SVG' || element.nodeName === 'IMG' || element.nodeName === 'DIV') {
            if (element.hasAttribute('disabled')) {
                element.classList.add('hidden')
            }
        }

        if (element.nodeName === 'A') {
            if (element.classList.contains('link-as-button')) {
                element.classList.add(...linkAsButtonDisable)
            } else {
                element.classList.add(...linkDisable)
            }
        }
    })
}

// Removes non-alert flash messages for when we're doing multiple turbo_stream operations
// on a page that message via flash.now
StreamActions.clearFlash = function () {
    const flash = document.getElementById('flash')
    Array.from(flash.children).forEach((element) => {
        if (element.classList.contains('non-alert')) {
            element.remove()
        }
    })
}

StreamActions.redirectIfAble = function () {
    const preferredUrl = this.getAttribute('preferred_url')
    const fallbackUrl = this.getAttribute('fallback_url')

    fetch(preferredUrl, {method: 'HEAD', mode: 'no-cors'})
        .then(() => {
            window.location.href = preferredUrl;
        })
        .catch(() => {
            Turbo.visit(fallbackUrl);
        });
}
