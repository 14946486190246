import UppyUploadController from './uppy_upload_controller'

// Connects to data-controller="uppy-upload-videos"
// Customizes the UppyUploadController to upload videos
// with 2 specialized requirements:
// 1. The org_id must be included in the upload data -- this is the organization that owns the video
// 2. The admin flag must be included in the upload data -- this is true if the user is uploading via their admin role
export default class UppyUploadVideosController extends UppyUploadController {
    static values = {
        orgId: Number,
        admin: {type: Boolean, default: false} // is the user uploading via their admin role?
    }

    successUpload(file, response) {
        const uploadData = super.prepareUploadData(file, response)
        const form = this.makeForData(uploadData)
        super.uploadFileData(form)
    }

    makeForData(uploadedFileData) {
        // console.debug("UppyUploadVideosController#makeForData")

        let formdata = super.prepareFormData(uploadedFileData)

        formdata.append("org_id", this.orgIdValue)

        if (this.hasAdminValue) {
            formdata.append("admin", this.adminValue)
        }

        return formdata
    }

    // openModel is always going to get called.  However, if we get triggered
    // via the ProviderUpload button, then we won't have the custom event and id will be undefined.
    // If we get triggered via the Admin Org dropdown, then we will have the custom event and id will be defined
    // which also means we're using our Admin role which needs to be captured and then delt with via the
    // makeForData method.
    openModal({detail: {id}}) {
        // console.debug(`UppyUploadVideosController#openModal -- id: ${id}`)

        // We only get here if the user used the Admin Org dropdown to select an org.
        // In this case, we override the default org and set the admin flag.
        if (id !== undefined) {
            this.orgIdValue = id
            this.adminValue = true
        }

        super.openModal()
    }
}
