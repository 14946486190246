import {Application} from "@hotwired/stimulus"
import "./stream_actions"
import Honeybadger from "@honeybadger-io/js";

const application = Application.start()

// Configure Stimulus development experience
application.debug = process.env.NODE_ENV === "development"
application.stimulusUseDebug = process.env.NODE_ENV === "development"

window.Stimulus = application
window.Honeybadger = Honeybadger

export {application}