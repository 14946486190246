import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="radio-buttons-required"
// It allows us to simulate that the form is valid/invalid based on
// at least one radio button being selected.
//
// On valid: allows the form submitButton to function
// on Invalid:
//   - disables the form submitButton
//   - shows a message that at least one radio button must be selected
//
export default class extends Controller {
    static targets = ["radioButton", "submitButton", "nonSelectedMessage"]
    static classes = ["invalid"]

    connect() {
        // console.debug("radio-buttons: ", this.validate())
        this.validate()
    }

    validate() {
        // console.debug("radio-buttons: validate()", this.isValid())

        if (this.isValid()) {
            this.submitButtonTarget.classList.remove(...this.invalidClasses)
            this.nonSelectedMessageTarget.classList.add("invisible")
        } else {
            this.submitButtonTarget.classList.add(...this.invalidClasses)
            this.nonSelectedMessageTarget.classList.remove("invisible")
        }
    }

    // A function that returns true if at least 1 radio button is checked
    isValid() {
        return (this.radioButtonTargets.some((radioButton) => radioButton.checked))
    }
}
