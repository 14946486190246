import {Controller} from "@hotwired/stimulus"
import {useClickOutside} from "stimulus-use"
import {actionsMixin} from "./actionsMixin"

// FIXME: This needs a major refactor as features have been added over time.
//  * Instead of '@notSelectedText' we should have <template> that we use normally
//  * setDestroyOn should be handled via another controller
//  * Should have multiple dispatch message types: connect, reset, select

// Connects to data-controller="listbox"
//
// Is all of the JS we need to make our Listbox work. It attempts
// some measure of usability by allowing the user to use the arrow
// keys to navigate the list and the space bar to select an item,
// and the escape key to close the list.
//
// When an item is selected, it dispatches a "selected" event with
// the name and id of the selected item. The event is dispatched
// on the element that has the data-controller="listbox" attribute.
export default class extends Controller {
    // @open: whether the listbox is open or not
    // @notSelectedText: alternative text to display when the listbox is in a non-selected state.
    //                   Note: this is only used if there is not selectedTarget or resetTarget LI element
    // @messageOnConnect: whether to dispatch a "selected content" event when the listbox is initially connected
    //                    AND WITH WHAT WOULD BE IT'S INITIAL STATE VALUES
    // @htmlElementClass: the class name to look for to see if we should do an HTML element copy
    //                    on this.processSelect() vs. just using the name value
    // @setDestroyOn:     the value to set the destroyTarget to when this item is selected
    // @resetOnSelect:    trigger a reset() when an item is selected -- this handles the use-case of where
    //                    we want to display the reset message instead of the last selected element.  For example,
    //                    the reset message my be 'Select a ....' and when the user selects an item, we want to
    //                    redisplay that message so they know they can keep selecting
    //
    // New:
    // Separate?
    // @orgHierarchy:     whether to support an organization hierarchy behavior/logic (ideally, moved
    //                    to a separate controller with an incestuous relationship via OUTLETS).
    //                    A mixin?  If present, then it gets called on enable()/disable() to
    //                    generate lists of ids to enable/disable with the UL
    // @orgList:          an Object keyed by org.id with at least .lft, .rgt., .parent_id to
    //                    implement parent/child/logic.  If present, then assume hierarchy processing
    //                    is enabled and honor @hideOnSelect and process correctly based on enable()/disable().
    //
    //                    If an outlet, then we can simply ask: do we have the outlet and if so, then
    //                    on enable()/disable(), we can ask for the list of id's to act on.
    //
    // For sure:
    // @deferSelected:    On select, hold off on processSelected() -- but message as if it was selected.
    //                    This will allow a 3rd party controller to control if the selection will be
    //                    accepted/rejected.
    // @hideOnSelect:     Instead of making a selected item unselectable, hide it instead (shrinking)
    //                    the select list of choices for the user.
    // @preventDefault:   on the click->action (how do we make this choice easy given the action is buried
    //                    in the RubyComponent code)?
    //
    //                    * With deferSelected, we need to 'disable' the entire dropdown processing until
    //                      the 3rd party controller has accepted/rejected the selection -- otherwise, who
    //                      knows what state the listbox will end up in?
    //                    * Need an enable() / disable() method to make an item selectable / not selectable.
    //                      The major use-case for this is an item that was selected was rejected externally
    //                      (for example, a 3rd party controller is managing a list of the selections to submit
    //                      as part of a form, and the user wants to delete one of the selections).
    //                    * If we moved the currentlySelected ID, NAME into a tracking value object, then
    //                      the rest of the code gets WAY cleaner
    //
    // @messageOnConnect: Should we dig up the dispatch values, but not set them first? after initializeButton()
    //                     and then send?
    //
    //
    // NOTES: Looks for class .item-select to determine if it should copy HTML nodes
    // into the button.  If we have element with that class, then we assume HTML.
    // If we don't, then we just use the name value without formatting.
    static values = {
        open: Boolean,
        notSelectedText: String,
        messageOnConnect: Boolean,
        htmlElementClass: {type: String, default: 'item-select'},
        setDestroyOn: String,
        resetOnSelect: Boolean,
        initialized: Boolean,
    }
    static targets = ["button", "hidden", "list", "selected", "reset", "destroy"]
    static classes = ["selected"]

    connect() {
        actionsMixin(this)
        // to be added/removed on selected
        this.classesOnSelect = this.hasSelectedClasses ? this.selectedClasses : ['opacity-60']

        // Used to close the listbox when the user clicks outside of it.
        // Instead of using clickOutside() we're using an stimulus action as this let's us dynamically add and remove it
        // so we don't spend resources processing events when the listbox is closed.
        useClickOutside(this)

        // Set the initial display state for button
        // With resetOnSelect, we need to turn that off if set for the initial connect
        // or we'll head into an infinite loop
        if (this.resetOnSelectValue) {
            this.resetOnSelectValue = false
            this.initializeButton()
            this.resetOnSelectValue = true
        } else {
            this.initializeButton()
        }

        this.supportBlurProcessing()
        this.initializedValue = true
    }

    // Used to set the initial display state for the button
    initializeButton() {
        if (this.hasSelectedTarget) {
            this.processSelect(this.selectedTarget, this.messageOnConnectValue)
        } else if (this.hasResetTarget) {
            this.processSelect(this.resetTarget, this.messageOnConnectValue)
        } else if (this.hasNotSelectedTextValue) {
            this.buttonTarget.innerHTML = this.notSelectedTextValue
            // FIXME: this should message too
        }
    }

    // Toggles the main dropdown open / closed
    toggle(event) {
        // console.debug("toggle: ", this.openValue, " name: ", event)

        // FIXME: do we still need this?
        if (event !== undefined) {
            event.preventDefault()
        }

        if (!this.openValue) {
            this.open()
        } else {
            this.close()
        }
    }

    // Opens the main dropdown
    // Always runs, so this must be idempotent
    open() {
        // console.debug("open")

        this.addAction('close', 'listbox2:click:outside')
        this.openValue = true
        this.listTarget.classList.remove("hidden")
        this.buttonTarget.setAttribute("aria-expanded", "true")
        this.buttonTarget.focus()
        this.dispatch("opened", {detail: {element: this.element}})
    }

    // Closes the main dropdown
    close() {
        // console.debug("close")

        if (!this.openValue) {
            return
        }

        // removeAction.bind(this)('close', 'listbox2:click:outside')
        this.removeAction('close', 'listbox2:click:outside')
        this.openValue = false
        this.listTarget.classList.add("hidden")
        this.buttonTarget.setAttribute("aria-expanded", "false")
        this.dispatch("closed", {detail: {element: this.element}})
    }


    // Reset the list box to the reset state
    // This is either the resetTarget or the notSelectedTextValue
    reset() {
        // console.debug("listbox: reset: ", this.buttonTarget.name)
        if (this.hasResetTarget) {
            this.processSelect(this.resetTarget)
        } else if (this.hasNotSelectedTextValue) {
            this.buttonTarget.innerHTML = this.notSelectedTextValue
            // FIXME: this needs to be handed by processSelect!
        } else {
            // console.error("listbox: reset: no resetTarget")
        }
        // this.supportBlurProcessing()
    }

    // External facing event -- only gets EVENTS from stimulus actions
    select(event) {
        // console.debug("select: ", event)

        if (event.target.tagName === 'LI')
            this.processSelect(event.currentTarget)
        else
            this.processSelect(event.currentTarget.closest("LI"))
    }

    // Does the HEAVY lifting of selecting an item
    // This means:
    // - copying the display content from the LI to the buttonTarget
    //   - This handles the use case of the buttonTarget being an input or
    //     button tag (which allows phrasing HTML content -- i.e., possibly multiple children within the LI that
    //     need to be copied)
    // - correctly setting any "display" attributes on the LI reflective of being the actively selected item
    // - setting the hidden input value
    // - updating the ARIA attributes (mostly -- FIXME: needs more work)
    // - And if messaging is enabled, dispatching a "selected" event
    processSelect(target, shouldMessage = true) {
        if (this.openValue) {
            this.close()
        }

        if (!this.resetOnSelectValue) {
            // Update the list
            // reset the previously selected item to be selectable and remove the check
            this.resetOldTarget()

            // Set the currently selected item to be non-selectable and show why -- it's been selected and has a check
            this.setUnselectable(target)

            // set the visible select box value
            if (target.getElementsByClassName(this.htmlElementClassValue).length === 1) {
                // First, remove all of the children nodes from inside the buttonTarget. Why does JS make this so hard?
                while (this.buttonTarget.hasChildNodes()) {
                    this.buttonTarget.removeChild(this.buttonTarget.firstChild)
                }

                const child = target.getElementsByClassName(this.htmlElementClassValue)[0]
                this.buttonTarget.appendChild(child.cloneNode(true))
            } else {
                this.buttonTarget.innerHTML = target.dataset.name
            }
            this.hiddenTarget.dataset.name = target.dataset.name
        }

        // FIXME: this is a hack and should be moved out of here to a separate controller than listens
        //      for the selected event and then does the right thing
        // set the hidden input value so this can be used in a nested form
        this.hiddenTarget.value = target.dataset.id
        if (this.hasSetDestroyOnValue && this.setDestroyOnValue === target.dataset.id) {
            this.destroyTarget.value = true
        } else if (this.hasDestroyTarget) {
            this.destroyTarget.value = false
        }

        // tell the world what's has changed -- if anyone is listening, it's up to them to do the right thing
        if (shouldMessage) {
            const isReset = this.hasResetTarget && target === this.resetTarget
            this.messageChange(target.dataset.name, target.dataset.id, isReset)
        }
        this.supportBlurProcessing()
    }

    // reset the previously selected item to be selectable and hide the check (if there is one)
    resetOldTarget() {
        if (!this.prevTargetId) {
            return
        }
        const oldTarget = document.getElementById(this.prevTargetId)

        this.setSelectable(oldTarget)
    }

    setSelectable(target) {
        target.classList.remove("pointer-events-none")
        target.classList.remove(...this.classesOnSelect)
        target.setAttribute('tabindex', '0')
    }

    // Set the currently selected item to be non-selectable and make it's check visible (if it has one)
    setUnselectable(target) {
        target.classList.add("pointer-events-none")
        target.classList.add(...this.classesOnSelect)
        target.setAttribute('tabindex', '-1')
        this.prevTargetId = target.id
    }

    disableTarget(id) {
        const target = this.listTarget.querySelector(`[data-id="${id}"]`)
        this.setUnselectable(target)
    }

    enableTarget(id) {
        const target = this.listTarget.querySelector(`[data-id="${id}"]`)
        this.setSelectable(target)
    }

    // Sends a StimulusJS dispatch with the newly selected controller element, selected: name and id
    messageChange(name, id, isReset) {
        // console.debug("messageChange: ", name, " id: ", id)
        const details = {
            element: this.element,
            name: name, id: id,
            isReset: isReset,
            onConnect: !this.initializedValue
        }

        this.dispatch("selected", {detail: details})
    }

    // Handles the blur event and handles wrap around
    // setTimeout to allow the event to finish processing before checking document.activeElement
    blur(event) {
        setTimeout(() => {
            // console.debug("blur")
            // still inside the listbox, so ignore
            if (this.listTarget.contains(document.activeElement)) {
                // console.debug("blur activeElement Return")
                return
            }

            if (event.target === this.liFirstElement) {
                // console.debug("blur wrap to end: ", this.liLastElement.id)
                this.liLastElement.focus()
            } else {
                // console.debug("blur wrap to front", this.liFirstElement.id)
                this.liFirstElement.focus()
            }
        }, 10)
    }

    // looks at the LI list to identify our wrap targets
    supportBlurProcessing() {
        // Support for wrapping around our listbox list
        const li_elements = Array.from(this.listTarget.getElementsByTagName("li")).filter((li) => {
            return !li.classList.contains("pointer-events-none")
        })

        if (li_elements.length === 0) {
            return
        }

        this.liFirstElement = li_elements[0]
        this.liLastElement = li_elements[li_elements.length - 1]
    }
}
