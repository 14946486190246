import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="update-link-url"
export default class extends Controller {

    update() {
        const url = new URL(document.URL)
        const queryParams = url.searchParams
        const target = new URL(this.element.href)

        for (const [key, value] of queryParams.entries()) {
            target.searchParams.set(key, value)
        }

        this.element.href = target.toString()
        this.element.click()
    }
}
