import {Controller} from "@hotwired/stimulus"

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import ProgressBar from '@uppy/progress-bar'
import Informer from '@uppy/informer'
import AwsS3 from "@uppy/aws-s3";

// Connects to data-controller="file-uploader"
// It has one big job: coordinate the upload with Shrine
//
// This setups up a direct upload via Shrine that DOES NOT promote until the user submits the form.
export default class extends Controller {
    // @uploadUrlValue is FULL URL to the Shrine upload endpoint -- only needed for uploadStoreValue === 'file'
    // @acceptValue is the file types that are allowed to be uploaded
    // @uploadModeValue is the kind of upload processing we require: s3, s3_multipart, file
    static values = {uploadUrl: String, accept: Array, uploadMode: String}

    // @uppyFileTarget is the file input tag that we're replacing with Uppy
    // @hiddenInputTarget is the hidden input that will hold the Shrine metadata
    // @uploadedImageTarget is the element that will be used to display the soon to be uploaded image
    // @informerTarget is the element that will display errors
    // @progressTarget is the element that will display the progress bar
    // @submitTarget is the submit button that will be shown once the image has been uploaded
    static targets = ['uppyFile', 'hiddenInput', 'imagePreview', 'uploadedImage', 'progress', 'informer', 'submit']

    connect() {
        this.uppyFileTarget.accept = this.acceptValue

        const uppy = new Uppy({
            // debug: true,
            // logger: window.Stimulus.debug ? Uppy.debugLogger : Uppy.noopLogger,
            autoProceed: true,
            restrictions: {
                allowedFileTypes: this.acceptValue
            }
        })

        // The way it reports errors with the uploader
        uppy.use(Informer, {
            target: this.informerTarget
        })

        // Progress bar IF things get slow
        uppy.use(ProgressBar, {
            target: this.progressTarget,
            hideAfterFinish: true
        })

        // All the upload magic is here -- responsible for actually uploading the file
        // to shrine
        if (this.uploadModeValue === 'file') {
            uppy.use(XHRUpload, {
                endpoint: this.uploadUrlValue
            })
        } else {
            // This is the s3 path.
            // It might also be the s3_multipart path, but that's not tested yet.
            uppy.use(AwsS3, {
                companionUrl: '/', // will call Shrine's pre-sign endpoint mounted on `/s3/params`
            })
        }

        // Handle the necessary post-upload to the browser but before form submit processing
        uppy.on('upload-success', (file, response) => {
            this.successProcessing(file, response)
        })

        // Our handle rolled file input (instead of Uppy's default)
        this.uppyFileTarget.addEventListener('change', (event) => {
            const files = Array.from(event.target.files)

            files.forEach((file) => {
                try {
                    uppy.addFile({
                        source: 'file input',
                        name: file.name,
                        type: file.type,
                        data: file,
                    })
                } catch (err) {
                    if (err.isRestriction) {
                        // handle restrictions
                        console.log('Restriction error:', err)
                    } else {
                        // handle other errors
                        console.error(err)
                    }
                }
            })
        })
    }

    // After a file has been successfully uploaded, we need to take these steps
    // in order to display the image for preview AND to format the data correctly
    // for Shrine to process on form submit
    successProcessing(file, response) {
        if (this.uploadModeValue === 'file') {
            this.uploadedImageTarget.src = response.uploadURL
        } else {
            this.uploadedImageTarget.src = URL.createObjectURL(file.data)
        }
        this.hiddenInputTarget.value = this.uploadedFileData(file, response, this.uploadModeValue)
        this.imagePreviewTarget.classList.remove('hidden')
    }

    // FIXME: how to handle MINIO?  Is that == to s3 or s3_multipart? and how to decide?
    // uploadedFileData transforms the response from the various upload handlers into the shrine
    // expected format.
    //
    // One has to wonder if this could be handled in the controller? Less JS is always better...
    uploadedFileData(file, response, uploadMode) {
        if (uploadMode === 's3') {
            const id = file.meta['key'].match(/^.*cache\/(.+)/)[1]; // object key without prefix

            return JSON.stringify(this.fileData(file, id))
        } else if (uploadMode === 's3_multipart') {
            const id = response.uploadURL.match(/\/cache\/([^\?]+)/)[1]; // object key without prefix

            return JSON.stringify(this.fileData(file, id))
        } else {
            return JSON.stringify(response.body['data'])
        }
    }

    // constructs uploaded file data in the format that Shrine expects
    fileData(file, id) {
        return {
            id: id,
            storage: 'cache',
            metadata: {
                size: file.size,
                filename: file.name,
                mime_type: file.type,
            }
        }
    }
}