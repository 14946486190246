import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js"

// Connects to data-controller="selectedGet"
// Listens for a "selected" event with a detail of {id: id}
// and then does a GET to the URL with the :key replaced with
// the id.
export default class extends Controller {
    static values = {
        url: String, // URL to use in the GET
        key: String, // the key to use in the replace function on the URL string
        kind: {type: String, default: "turbo-stream"},
    }

    connect() {
        // console.debug("selected-get connected", this.element)

        if (!this.hasUrlValue) {
            console.error("selected-get missing value: url")
        }

        if (!this.hasKeyValue) {
            console.error("selected-get missing value: key")
        }
    }

    async selected({detail: {id}}) {
        // console.debug("selected-get", name, id)

        let url = this.urlValue
        url = url.replace(this.keyValue, id)

        // let response = await get(url, {responseKind: this.kind})
        let response = await get(url)
        if (response.ok) {
        }
    }
}
