import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
    static targets = ["dialog"]

    connect() {
        this.dialogTarget.addEventListener("cancel", this.cancelHandler.bind(this))
        this.open()
    }

    disconnect() {
        this.dialogTarget.removeEventListener("cancel", this.cancelHandler.bind(this))
    }

    open() {
        this.dialogTarget.showModal()
    }

    close(event) {
        // Because the closing of the dialog might happen outside of stimulus's ability to process
        // via data-action -- we tell the world via a custom event that the dialog has closed so
        // any other controller that was assuming the dialog is open can figure out what to do
        // with it closed.
        this.dispatch("close")
        this.dialogTarget.close()
    }

    // Modal dialogs have a cancel event that we MUST listen for so we can process the event
    // under the control of stimulus.
    cancelHandler(event) {
        this.close()
    }
}
