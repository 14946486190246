import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

// Connects to data-controller="sort-encounter"
// This is a basic sortable controller that really just sorts on the page.
// Turns out, for encounters, that is *all* we need.
export default class extends Controller {
  connect() {
    this._sortable = Sortable.create(this.element, {
      handle: '.drag-handle',
      animation: 150,
    })
  }

  disconnect() {
    if (this._sortable) {
      this._sortable.destroy()
      this._sortable = undefined
    }
  }
}
