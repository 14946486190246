import {Controller} from "@hotwired/stimulus";
import Plyr from "plyr";

export default class extends Controller {
    static values = {
        title: String,
        autoplay: {type: Boolean, default: false}
    }
    static targets = ["player"]

    connect() {
        // Setup the Plyr instance and get it ready for use
        this.videoPlayer = new Plyr(this.playerTarget, {
            controls: ['play-large', 'play', 'progress', 'volume', 'mute', 'current-time'],
            hideControls: false,
            clickToPlay: true,
            autoplay: this.autoplayValue,
            title: this.titleValue, // This is for better ARIA when we get there
        })
    }

    disconnect() {
        this.close()
    }

    close(event) {
        if (this.videoPlayer) {
            this.videoPlayer.stop()
            this.videoPlayer.destroy()
        }

        this.videoPlayer = null
    }

    stop(event) {
        if (this.videoPlayer) {
            this.videoPlayer.stop() // stop the player
        }
    }

    play(event) {
        if (this.videoPlayer) {
            this.videoPlayer.play().then() // play the player
        }
    }

    togglePlay(event) {
        if (this.videoPlayer) {
            this.videoPlayer.togglePlay()
        }
    }

    cancel(event) {
        this.preventDefault()
        alert("Cancel triggered")
    }
}