import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="position-updater"
// this controller runs before a form is submitted and updates the position targets
// with the current position of the child elements.
//
// This is useful when you have a sortable list and you want to update the positions
// prior to submitting the form with the position value set so the they are updated
// as part of the form submission.
//
// @positionTarget: a text_field that holds the position value for this form. Code
//                 works with a single position element OR multiples (like a nested
//                 form)
// @formTarget: the form to submit when the form is submitted.  If not specified, looks
//              to the form element of the submit button that was clicked.
export default class extends Controller {
    static targets = ['place', 'form']

    connect() {
    }

    // prevents the submission of the form so we can update the position targets
    // with the current position of the child elements.  It then triggers the
    // form submit
    submit(event) {
        if (!this.hasFormTarget && !this.element.form) {
            // console.debug("PositionUpdaterController(submit event): missing formTarget: ", this.element)
            return
        }

        // prevent the form from submitting until we can update the position targets
        event.preventDefault()

        // console.debug(`PositionUpdaterController: place targets: ${this.placeTargets.length}`)
        // update the position targets with the current position of the child elements

        // we can't use the index available from the forEach because we have to skip any hidden elements
        let index = 0

        if (this.hasPlaceTarget) {
            this.placeTargets.forEach((el) => {
                if (!el.disabled) {
                    el.value = index
                    index++
                }
            })
        }

        // make sure turbo does the submit!
        const form = this.formTarget || event.target.form
        form.requestSubmit()
    }
}
