import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="dedup"
// This controller attempts to be smart about making sure that two parts of the screen (browser page)
// don't have the same element on it at the same time.  When it finds a dup, it applies the onMatchClasses
// to the check element that match.
//
// @sourceItemTarget: the element that we are checking against
// @checkItemTarget: the element that we are checking -- checkItems have the CSS applied
// @labelValue: allows one to fake dom_id(x, :label) by adding a labelValue the id being checked
// @onMatchClass: the class(es) to apply to the checkItem when a match is found -- applied to the
//                checkItemTarget
// @tabindexValue: should we tabindex='-1' the checkItem when a match is found and remove it when
//                 the match is removed?
//
// FIXME: the label value is ONLY applied to the checkItemTarget and that is an assumption that
//   works for now, but may not work in the future.
export default class extends Controller {

    static targets = ['sourceItem', 'checkItem']
    static values = {
        label: String, // allows us to mimic dom_id(x, :label)
        tabindex: Boolean
    }
    static classes = ['onMatch']

    connect() {
        // console.debug('deDupController: connect')
    }

    // when search results from the category are displayed
    checkItemTargetConnected(checkItem) {
        // console.debug('deDupController: onCheckItemTargetConnect', checkItem.id)

        if (!this.hasSourceItemTarget) {
            return
        }

        const checkId = checkItem.dataset.dedupCheckId

        this.sourceItemTargets.forEach(sourceItem => {
            if (!sourceItem.classList.contains('hidden') && checkId === sourceItem.dataset.dedupSourceId) {
                if (this.hasOnMatchClass) {
                    checkItem.classList.add(...this.onMatchClasses)
                    this.turnOffTabFocus(checkItem)
                }
            }
        })
    }

    // When a search result has been promoted to a pending PlaylistLibrary in the form
    sourceItemTargetConnected(sourceItem) {
        // console.debug('deDupController: onSourceItemTargetConnect', sourceItem.id)

        // if we don't have both sets of required data, then we can't do anything
        // or if the sourceItem is hidden, then that means it was a PlaylistLibrary and
        // were going to delete it, so we need to be able to 'see it' in the search
        // results to be able to add it back if needed
        if (!this.hasCheckItemTarget || sourceItem.classList.contains('hidden')) {
            return
        }

        const sourceId = sourceItem.dataset.dedupSourceId

        this.checkItemTargets.forEach(checkItem => {
            if (sourceId === checkItem.dataset.dedupCheckId) {
                if (this.hasOnMatchClass) {
                    checkItem.classList.add(...this.onMatchClasses)
                    this.turnOffTabFocus(checkItem)
                }
            }
        })
    }

    // When a PlaylistLibrary or Library (not yet part of the playlist) is removed
    sourceItemTargetDisconnected(sourceItem) {
        // console.debug('deDupController: onSourceItemTargetConnect', sourceItem.id)

        // if we don't have both sets of required data, then we can't do anything
        if (!this.hasCheckItemTarget) {
            return
        }

        const sourceId = sourceItem.dataset.dedupSourceId

        this.checkItemTargets.forEach(checkItem => {
            if (sourceId === checkItem.dataset.dedupCheckId) {
                if (this.hasOnMatchClass) {
                    checkItem.classList.remove(...this.onMatchClasses)
                    this.turnOnTabFocus(checkItem)
                }
            }
        })
    }

    // checkAll does the transitive closure on both sets of targets.  Basically,
    // it's an emergency, break glass -- test everything function.  As such,
    // it's more expensive than the evented versions.
    checkAll() {
        // console.debug('deDupController: checkAll')

        // if we don't have both sets of required data, then we can't do anything
        if (!this.hasSourceItemTarget || !this.hasCheckItemTarget) {
            return
        }

        this.sourceItemTargets.forEach(sourceItem => {
            const sourceId = sourceItem.dataset.dedupSourceId

            this.checkItemTargets.forEach(checkItem => {
                const checkId = checkItem.dataset.dedupCheckId

                if (sourceId === checkItem.dataset.dedupCheckId) {
                    if (this.hasOnMatchClass) {
                        checkItem.classList.add(...this.onMatchClasses)
                        this.turnOffTabFocus(checkItem)
                    }
                }
            })
        })
    }

    // Handles setting tabindex to -1 on all focusable sub elements in the element
    turnOffTabFocus(el) {
        if (!this.tabindexValue) {
            return
        }

        ['a', 'button', 'input', 'select', 'textarea'].forEach(tag => {
            Array.from(el.getElementsByTagName(tag)).forEach(e => {
                if (e.hasAttribute('tabindex')) {
                    // remember the original tabindex so we can restore it later
                    e.setAttribute('data-original-tabindex', e.getAttribute('tabindex'))
                }
                e.setAttribute('tabindex', '-1')
            })
        })
    }

    // Handles restoring tabindex to the original value, if there was one, on all focusable sub elements in the element
    turnOnTabFocus(el) {
        if (!this.tabindexValue) {
            return
        }

        ['a', 'button', 'input', 'select', 'textarea'].forEach(tag => {
            Array.from(el.getElementsByTagName(tag)).forEach(e => {
                if (e.hasAttribute('data-original-tabindex')) {
                    // we originally had a tabindex, so we restore it
                    e.setAttribute('tabindex', e.getAttribute('data-original-tabindex'))
                    e.removeAttribute('data-original-tabindex')
                } else {
                    // we didn't originally have a tabindex, so we remove it' to get back to normal
                    e.removeAttribute('tabindex')
                }
            })
        })
    }
}
