import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js";

// Connects to data-controller="selected-visit"
// Just like selected-get, but instead of using request.js, it does
// a turbo-visit to the URL with the :key replaced with the id.
export default class extends Controller {
  static values = {
    url: String, // URL to use in the GET
    key: String, // the key to use in the replace function on the URL string
    // add 'key2' if needed
  }

  connect() {
    // console.debug("selected-visit connected", this.element)

    if (!this.hasUrlValue) {
      console.error("selected-visit missing value: url")
    }

    if (!this.hasKeyValue) {
      console.error("selected-visit missing value: key")
    }
  }

  async selected({detail: {id}}) {
    // console.debug("selected-visit", name, id)

    let url = this.urlValue
    url = url.replace(this.keyValue, id)

    Turbo.visit(url)
  }
}
