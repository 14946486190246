import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

// Connects to data-controller="browser-timezone"
export default class extends Controller {
  connect() {
    const tz = new Intl.DateTimeFormat().resolvedOptions().timeZone

    Cookies.set("browser.timezone", tz, {sameSite: 'strict', secure: true});
  }
}
