import {Controller} from "@hotwired/stimulus"
import {useDebounce} from "stimulus-use"

// Connects to data-controller="submit-on-event"
// Triggers a form submit based on an external event
// with a user configurable debounce that allows
// differentiation between keyboard and touch devices
export default class extends Controller {
    static values = {
        keyboard: {type: Number, default: 300},
        touch: {type: Number, default: 1000}
    }

    static debounces = ['submit']

    connect() {
        // console.debug('SubmitController#connect')

        if (this.isTouchSupported) {
            // console.debug("setting debounce to touch")
            useDebounce(this, {wait: this.touchValue})
        } else {
            // console.debug("setting debounce to keyboard")
            useDebounce(this, {wait: this.keyboardValue})
        }
    }

    submit(event) {
        // console.debug('SubmitController#submit', event)
        // event.preventDefault()

        this.element.requestSubmit()
    }

    get isTouchSupported() {
        // console.debug("isTouchSupported: ", 'ontouchstart' in window || navigator.maxTouchPoints)
        return 'ontouchstart' in window || window.navigator.maxTouchPoints
    }
}
