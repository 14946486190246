import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="org-display"
// Shows / Hides levels of the org charge view based on the selected 'radio' button
export default class extends Controller {
    static values = {id: String}
    static targets = ["radio"]

    connect() {
        if (this.hasRadioTarget) {
            const level = this.radioTargets.find(target => target.checked == true).value
            this._setUrl(level)
            this._showHide(level)
        }
    }

    change(event) {
        this._setUrl(event.target.value)
        this._showHide(event.target.value)
    }

    _showHide(level) {
        const tree = document.getElementById(this.idValue)
        const nodes = tree.querySelectorAll("li")

        Array.from(nodes).forEach(node => {
            if (node.dataset.depth >= level) {
                node.classList.add("hidden")
            } else {
                node.classList.remove("hidden")
            }
        })
    }

    _setUrl(level) {
        const url = new URL(window.location.href)
        url.searchParams.set("level", level)
        window.history.pushState({}, "", url)
    }
}
