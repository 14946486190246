import FileUploader from './file_uploader_controller'
import Cropper from 'cropperjs'

// We're importing the cropperjs CSS file directly through the asset pipeline

// Connects to data-controller="cropping-file-uploader"
// This is a very specialized controller that is only used on the avatar upload page.
// It has one big job: coordinate the upload with Shrine and CropperJS.
export default class CroppingFileUploader extends FileUploader {
    cropper(file, response) {
        const hiddenInputTarget = this.hiddenInputTarget

        if (this.uploadModeValue === 'file') {
            this.uploadedImageTarget.src = response.uploadURL
        } else {
            this.uploadedImageTarget.src = URL.createObjectURL(file.data)
        }
        hiddenInputTarget.value = this.uploadedFileData(file, response, this.uploadModeValue)

        this.submitTarget.classList.remove('hidden')
        this.imagePreviewTarget.classList.remove('hidden')

        const copper = new Cropper(this.uploadedImageTarget, {
            aspectRatio: 1,
            viewMode: 1,
            guides: false,
            autoCropArea: 1.0,
            background: false,
            minContainerHeight: 160,
            minContainerWidth: 160,
            crop: function (event) {
                let data = JSON.parse(hiddenInputTarget.value)
                data['metadata']['crop'] = event.detail
                hiddenInputTarget.value = JSON.stringify(data)
            }
        })
    }

    successProcessing(file, response) {
        super.successProcessing(file, response);
        this.cropper(file, response);
    }
}