import ToggleController from "./toggle_controller";
import {actionsMixin} from "./actionsMixin";
import {useClickOutside} from "stimulus-use"

// Connects to data-controller="toggle-click-outside"
// Pretty much a toggle controller, but with awareness of a click outside event
// that will close the toggleable.
// @outsideEventTarget - an optional target that will be used as the click outside target
//                       instead of this.element (the default)
export default class ToggleClickOutsideController extends ToggleController {
    static targets = ["outsideElement"]

    connect() {
        super.connect()
        actionsMixin(this)
        useClickOutside(this)
        // console.debug("ToggleClickOutsideController connected and has targets", this.hasToggleableTarget)
    }

    // Iff we get a target from the turbo_stream, we need that element to
    // be the click outside target.
    outsideElementTargetConnected(element) {
        console.debug("ToggleClickOutsideController toggleableTargetConnected", element)

        if (this.hasOutsideElementTarget) {
            useClickOutside(this, {element: this.outsideElementTarget})
        } else {
            useClickOutside(this, {element: element})
        }
    }

    toggle(event) {
        // console.debug("ToggleClickOutsideController#toggle")

        if (this.openValue) {
            console.debug("openValue is true -- removing actions")
            this.removeActions()
            super.toggle(event)
        } else {
            console.debug("openValue is false -- adding actions")
            this.addActions()
            super.toggle(event)
        }
    }

    // Close the toggleable if the user clicks outside of the toggleable
    // (say, another object on the page somewhere....)
    clickOutside(event) {
        console.debug("ToggleClickOutsideController clickOutside", this.element.id, this.openValue, event.target)

        if (this.openValue) {
            console.debug("clickOutside open: ", this.openValue)
            // event.stopPropagation()
        }

        this.removeActions()

        // if (!this.openValue) return

        this.hide()
    }

    addActions() {
        this.addAction("clickOutside", "keydown.esc")
    }

    removeActions() {
        this.removeAction("clickOutside", "keydown.esc")
    }
}
