import {Controller} from "@hotwired/stimulus"
import {get} from "@rails/request.js"
import {useDebounce} from "stimulus-use";

// Connects to data-controller="encounter-creation"
export default class extends Controller {
    static values = {
        librariesUrl: String,
        count: {type: Number, default: 0},
        duration: {type: Number, default: 0},
        playlistIds: Object,
        trackedIds: {type: Array, default: []}
    }
    static targets = ["libraryItems", "count", "duration", "playlist", "submitButton", "countZero"]
    static debounces = ["stateChangeMessageUser"]

    connect() {
        useDebounce(this)

        // This is going to repopulate the trackedIds as each EncounterVideo is
        // fetched and the encounterVideosTargetConnected is called
        // const trackedIds = this.trackedIdsValue
        // this.trackedIdsValue = []
        // trackedIds.forEach((libraryId) => {
        //     this.requestLibraryItem(libraryId)
        // })

        // initialize the submitButtonState
        this.stateChangeMessageUser()

        // We only have to do this once since it works against the trackedIds array
        this.processPlaylists()

        // The form this is attached to starts hidden.  This allows this
        // controller to process without creating flashes of content as
        // it processes incrementally.

        // Remove the hidden class to show the form when ready.
        this.element.classList.remove('hidden')
    }

    // We have to be careful about any events generated by sortable.js.
    // Thankfully, anything sortable is manipulating has a class added to
    // it so we can filter those out and ignore the event.
    encounterVideoTargetConnected(item) {
        // console.debug('EncounterCreationController#libraryItemsConnected', item)
        if (item.classList.contains('sortable-ghost')
            || item.classList.contains('sortable-chosen')
            || item.classList.contains('sortable-drag')) {
            // these are fake items during Dragging from sortable js
            return
        }
        const dom_id = this.idAsDomId(item.dataset.libraryId)
        const libraryItem = document.getElementById(dom_id)
        const duration = parseInt(item.dataset.videoDuration)

        this.durationValue += duration
        this.countValue += 1

        libraryItem.classList.add('hidden')
        const section = libraryItem.closest('section')
        this.showHideSectionHeading(libraryItem)
        this.stateChangeMessageUser()

        // Javascript is wonderful...
        this.trackedIdsValue = [...this.trackedIdsValue, item.dataset.libraryId]
        this.processPlaylists()
    }

    // A Library + button was clicked
    // This will trigger a request to the server for a turbo-stream that will
    // create an encounterVideo and THAT will result in a new encounterVideoTargetConnected
    addLibraryItem(event) {
        event.preventDefault()

        // this is the library item and we clicked the + button
        const libraryItem = event.target.closest('.library-item')
        // console.debug('EncounterCreationController#addLibraryItem', libraryItem)

        // Ask the server to create and encounterVideo
        const id = this.stripIdFromDomId(libraryItem.id)
        this.requestLibraryItems([id])
    }

    // Process a click on + playlist button
    // For each library id in the playlist:
    // for each library id not already in the encounter (trackedIds), request the library item
    // NOTE: we collect the ids that we need to process and then request them all at once
    //       to avoid a bunch of requests to the server and to have a way to guarantee
    //       they are returned in the correct order.
    addPlaylist(event) {
        event.preventDefault()
        const playlistItem = event.target.closest('.playlist-item')
        const libraryId = playlistItem.id.split('_')[1]

        if (this.hasPlaylistIdsValue) {
            const ids = this.playlistIdsValue[libraryId].filter((libraryId) => {
                // Make sure this library item isn't already in the encounter
                if (!this.trackedIdsValue.includes(libraryId)) {
                    return libraryId
                }
            })

            this.requestLibraryItems(ids)
        }
    }

    // An encounterVideo as requested to be removed
    // * remove the encounterVideo from the list
    // * show the associated library item
    // * remove the library id from the tracking list
    // * update the count and duration
    // * stateChangeMessageUser
    // This can be done last because it's really a visible only change
    // * process the playlists
    removeEncounterVideo(event) {
        event.preventDefault()

        const encounterVideoItem = event.target.closest('.encounter-video-item')
        const destroyInput = encounterVideoItem.querySelector('input[name*="_destroy"]')
        const libraryId = encounterVideoItem.dataset.libraryId

        // update the bookkeeping, either way
        const duration = parseInt(encounterVideoItem.dataset.videoDuration)

        this.durationValue -= duration
        this.countValue -= 1

        if (destroyInput) {
            // this is an existing record, mark for destruction
            destroyInput.value = 1
            encounterVideoItem.classList.add('hidden')
        } else {
            // this is a non-persisted record, just remove it
            encounterVideoItem.remove()
        }

        // Now re-display the associated library item
        const library = document.getElementById(this.idAsDomId(libraryId))
        library.classList.remove('hidden')
        const section = library.closest('section')
        this.showHideSectionHeading(library)

        this.stateChangeMessageUser()

        // Remove this library id from the tracking list
        // Javascript is wonderful...
        this.trackedIdsValue = this.trackedIdsValue.filter((trackedId) => trackedId !== libraryId)
        this.processPlaylists()
    }

    // on any connected/disconnected event processPlaylists() checks to state
    // of the trackedIds vs. the playlistIds and marks the playlist as appropriate
    processPlaylists() {
        if (!this.hasPlaylistTarget) {
            return
        }

        this.playlistTargets.forEach((playlist) => {
            let id = this.stripIdFromDomId(playlist.id)
            let playlistIds = this.playlistIdsValue[id]

            let idsInEncounter = playlistIds.filter(id => this.trackedIdsValue.includes(id))

            // FIXME: this should be a classValue
            if (idsInEncounter.length === 0) {
                playlist.classList.remove('opacity-50')
                playlist.classList.remove('hidden')
            } else if (idsInEncounter.length < playlistIds.length) {
                // this playlist has some videos in the encounter
                playlist.classList.add('opacity-50')
                playlist.classList.remove('hidden')
            } else {
                // this playlist is completely in the encounter
                playlist.classList.add('hidden')
            }
            this.showHideSectionHeading(playlist)
        })
    }

    // Displays the count of encounterVideos for this encounter
    countValueChanged() {
        // console.debug('EncounterCreationController#countValueChanged', this.countValue)
        this.countTarget.textContent = this.countValue
    }

    // Displays the duration of the encounterVideos for this encounter
    durationValueChanged() {
        const minutes = Math.floor(this.durationValue / 60)
        const seconds = this.durationValue - (minutes * 60)

        this.durationTarget.textContent = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }

    // Calls show with the library id
    requestLibraryItems(libraryIds) {
        const encounterId = document.getElementById('encounter-id').value
        const userId = document.getElementById('user-id').value

        // console.debug(this.librariesUrlValue.replace(':id', libraryId))
        let relativeUrl = this.librariesUrlValue
        let absoluteUrl = window.location.origin + relativeUrl

        let url = new URL(absoluteUrl)
        libraryIds.forEach(id => {
            url.searchParams.append('ids', libraryIds)
        })
        url.searchParams.append('encounter_id', encounterId)
        url.searchParams.append('user_id', userId)

        get(url, {responseKInd: 'turbo-stream'})
            .then(response => {
                // We don't need to do anything, request.js will process the turbo-stream
            })
            .catch(error => {
                console.error('EncounterCreationController#requestLibraryItem', error)
            })
    }

    stateChangeMessageUser() {
        // console.debug('EncounterCreationController#userMessageState')

        const patientName = document.getElementById('patient-name').value
        const patientDoB = document.getElementById('patient-dob').checkValidity()

        // The message on empty encounterVideos
        if (this.countValue) {
            this.countZeroTarget.classList.add('invisible')
        } else {
            this.countZeroTarget.classList.remove('invisible')
        }

        this.submitButtonTarget.disabled = !(this.countValue && patientName && patientDoB);
    }

    // Shows/Hides the section heading based on the visibility of the contained items
    // When a heading goes to 0 visible items, then the section is hidden too.
    showHideSectionHeading(item) {
        const section = item.closest('section')

        if (section.querySelector('section > div:not(.hidden)') === null) {
            section.classList.add('hidden')
        } else {
            section.classList.remove('hidden')
        }
    }

    // Converts an integer or string id value to a dom_id formatted string
    idAsDomId(id) {
        return `library_${id}`
    }

    // Converts a dom_id formatted string to an integer id
    stripIdFromDomId(domId) {
        return domId.split('_')[1]
    }
}
