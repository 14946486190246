import {Controller} from "@hotwired/stimulus"
import Sortable from "sortablejs"
import {FetchRequest} from "@rails/request.js";

// Connects to data-controller="sortable"
// @url: the url to submit the request to when using the request.js put request
// @indexAdjust: A number to add to the newIndex prior to submitting the request.
//               This is helpful because the sortableJS counts *EVERY* child element
//               below the sortable parent and if you have any other elements that
//               might be hidden or for other purposes, they get counted and screw
//               up the submitted place.
//
//               This value allows one to compensate for that.
//
// If you include the sortable outlet, then this controller will apply the showHideSortables
// class to them. This is useful if you have multiple sortables and you want to make it clear
// that they are not drop targets.
export default class extends Controller {
    static values = {
        url: String,
        // Adds (allows negative numbers for subtraction) to the newIndex prior to submitting the request
        indexAdjust: Number,
    }

    static outlets = ['sortable']
    static classes = ['showHideSortables']

    // setup sortablejs
    connect() {
        this._sortable = Sortable.create(this.element, {
            draggable: '.draggable',
            handle: '.drag-handle',
            animation: 150,
            onStart: this.start.bind(this),
            onEnd: this.end.bind(this)
        })
    }

    // clean up correctly and without leaking when the controller is removed
    disconnect() {
        if (this._sortable) {
            this._sortable.destroy()
            this._sortable = undefined
        }
    }

    // used if we have outlets that we need to apply the showHideSortables class to
    showHide(state) {
        const that = this
        this.sortableOutlets.forEach(el => {
            if (el === that) {
                return // for thee, not for me....
            }

            if (state === 'show') {
                el.show()
            } else {
                el.hide()
            }
        })
    }

    // We only have one start (starting to drag) event and that is dependent on if
    // we have any outlets to apply the showHideSortables class to.
    start(event) {
        if (this.hasSortableOutlet) {
            this.showHide('hide')
        }
    }

    // The drag event has ended, so we need to show (if needed) and then notify rails of the results.
    // This includes building a FormData object with the place in it
    // and submitting a turbo_stream patch request to the url.
    async end(event) {
        if (this.hasSortableOutlet) {
            this.showHide('show')
        }

        // If this doesn't have a URL, then we don't need to submit anything -- we're just sorting on the screen
        if (this.urlValue) {
            const request = new FetchRequest('patch', this.makeUrl(event), {
                body: this.makeData(event),
                responseKind: 'turbo-stream'
            })
            const response = await request.perform()
            if (response.ok) {
                const _text = await response.text
            }
        }
    }

    // So we can override for other use cases
    makeUrl(event) {
        let id = event.item.dataset.id
        const url = this.urlValue.replace(":id", id)
        return (url)
    }

    // makeData creates the FormData object with the place in it
    // NOTE: it applies the indexAdjustValue to allow us to compensate for
    //      other elements that might be in the sortable parent that we don't
    //      want to be counted in the place.
    makeData(event) {
        // console.debug("makeData: ", event)
        let data = new FormData()
        data.append("place", event.newIndex + this.indexAdjustValue)

        return (data)
    }

    hide(el) {
        this._sortable.option('disabled', true)
        this.element.classList.add(...this.showHideSortablesClasses)
    }

    show(el) {
        this._sortable.option('disabled', false)
        this.element.classList.remove(...this.showHideSortablesClasses)
    }
}
