import {Controller} from '@hotwired/stimulus'
import {useClickOutside, useTransition} from "stimulus-use";
import {actionsMixin} from "./actionsMixin";

// @menuTarget: is the element that contains the dropdown menu content
// @buttonTarget: is the element that is annotated with aria-* attributes showing the dropdown state
// @openValue: tracks the open / close state of the dropdown. It's managed automatically by the dropdown controller.
// @zIndexValue: has the controller manager the z-index on the menuTarget elements.  This is useful
//               when you have multiple dropdowns on the page and they are close enough to each other
//               they overlap.
//
// The various useTransition data attributes are here as standard stimulus classes.
//
// notifiableTargets: are send a message about the open/close state via dispatch(Event)

export default class extends Controller {
    static targets = ['menu', 'button', 'notifiable']

    static values = {open: Boolean, zIndex: Number}

    static classes = ['hidden', 'enterActive', 'enterFrom', 'enterTo', 'leaveActive', 'leaveFrom', 'leaveTo']

    connect() {
        actionsMixin(this)
        // add transition handling
        // We do it this way so we have access to the classes that will be applied by the transition controller,
        useTransition(this, {
            element: this.menuTarget,
            enterActive: this.hasEnterActiveClass ? this.enterActiveClasses.join(' ') : null,
            enterFrom: this.hasEnterFromClass ? this.enterFromClasses.join(' ') : null,
            enterTo: this.hasEnterToClass ? this.enterToClasses.join(' ') : null,
            leaveActive: this.hasLeaveActiveClass ? this.leaveActiveClasses.join(' ') : null,
            leaveFrom: this.hasLeaveFromClass ? this.leaveFromClasses.join(' ') : null,
            leaveTo: this.hasLeaveToClass ? this.leaveToClasses.join(' ') : null,
            hiddenClass: this.hasHiddenClass ? this.hiddenClass : 'hidden'
        })

        useClickOutside(this, {dispatchEvent: true})

        const menuLinks = this.menuTarget.querySelectorAll('a')
        this.menuLinkFirst = menuLinks[0]
        this.menuLinkLast = menuLinks[menuLinks.length - 1]
    }

    disconnect() {
        this.hide()
    }

    // Public API: toggle the open / close state of the dropdown
    toggle(event) {
        if (!this.openValue) {
            this.show(event)
        } else {
            this.hide(event)
        }
    }

    // do the work to show the dropdown
    show(event) {
        this.openValue = true
        this.msgToNotifiable('show')
        this.addAction('hide', 'dropdown:click:outside')
        this.addAction('hide', 'keydown.esc@window')

        // this method is added by mixing in useTransition during connect().
        // It triggers the enter transition on the menuTarget element
        this.enter()

        // this is defensive coding -- there better be a buttonTarget
        if (this.hasButtonTarget) {
            this.buttonTarget.setAttribute('aria-expanded', 'true')
        }
    }

    // do the work to hide the dropdown
    hide(event) {
        if (!this.openValue) {
            return
        }
        this.openValue = false
        this.msgToNotifiable('hide')
        this.removeAction('hide', 'dropdown:click:outside')
        this.removeAction('hide', 'keydown.esc@window')

        // this method is added by mixing in useTransition during connect().
        // It triggers the leave transition on the menuTarget element
        this.leave()

        if (this.hasButtonTarget) {
            this.buttonTarget.setAttribute('aria-expanded', 'false')
        }
    }

    // send a message about the current menu state (open/closed) to
    // any notifiable targets (if any exist)
    msgToNotifiable(type) {
        if (!this.hasNotifiableTarget) {
            return
        }

        this.notifiableTargets.forEach((el) => {
            this.dispatch(type, {target: el})
        })
    }

    // Detects tabbing out of the menu and makes it wrap around if necessary
    // We use setTimeout() to give the event time to complete before we check the activeElement
    blur(event) {
        setTimeout(() => {
            // We're still inside the menu area, so no processing required
            if (this.menuTarget.contains(document.activeElement)) {
                return
            }

            // if we get here, we're tabbing AND we're outside of the menu area
            if (event.target === this.menuLinkFirst) {
                this.menuLinkLast.focus()
            } else {
                this.menuLinkFirst.focus()
            }
        }, 10)
    }
}