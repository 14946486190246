import {Controller} from "@hotwired/stimulus"
import {useClickOutside} from "stimulus-use"
import {actionsMixin} from "./actionsMixin"

// Connects to data-controller="listbox"
export default class extends Controller {
    static values = {
        // Internal state of the listbox showing choices or not (think toggle)
        open: Boolean,
        // An alternative to a non-selected element in the display list.
        // Should this be a template of a real element? (why? -- we could simply
        // declare id: 0, name: '' as the well known empty state)
        notSelectedText: String,
        // Message onConnect after the listbox is connected and the default state is set.
        // (messaging select can be right in the edit/update use-cases)
        messageOnConnect: Boolean,
        // if this is true, instead of showing the last selected item on successful select, reset()
        // The somewhat complex rules for how the listbox handles selection and deselection.
        // The build-in default maps to the existing default behavior:
        // * immediately re-enable the previously selected LI/item and deselect the currently selected LI/item
        // This object allows for new behaviors:
        // * hideResetOnSelect: if true, then the reset LI/item will be hidden once a selection is made.
        //                      This means that listbox can not be put back into a reset state.
        // * processImmediate: if false, then the listbox will dispatch the selected info (as if normal), but
        //                     will not actually process the selection until it receives an acceptDeferred()
        //                     or rejectDeferred() message.  The listbox will be disabled until the deferred
        //                     selection is accepted or rejected.
        // * disableAutoReenable: if true, then the listbox will re-enable the previously selected LI/item (the default)
        //                        if false, then the listbox will not re-enable any previously selected and, hence,
        //                        disabled LI/items. This enables the listbox to be used to collected multiple-selections.
        // * resetOnSelect:  if true, then the listbox will reset to it's initial state on a select.  Instead of
        //                   showing the last selected item, it will show the resetState.  This is useful
        //                   when the listbox is being used to select a single item from a list of items.
        // * hierarchical:    If enabled, instead of processing a single LI/item, the listbox will process the
        //                    lineage of the selected LI/item according to the rules above (processImmediate,
        //                    disableAutoReenable).
        //   * data:          The data to be used to determine the lineage of the selected LI/item.  This is
        //                    expected to be a JSON object in the form of {id: {lft:, rgt:, parent_id:}} from
        //                    AwesomeNestedSet. If this has a value, then we're in Hierarchical mode.
        //  * lineage:        The lineage to be processed: descendants or ancestors (i.e., process down or up the
        //                    tree from the selected LI/item).  This is expected to be a string with a value of
        //                    'descendants' or 'ancestors'. Defaults: 'descendants'
        //  * lineageCheck:   If true, then the listbox will verify that all of the selected LI/items do not have
        //                    an existing selection in it's path (i.e., if in descendants mode, then the listbox
        //                    will verify that all of the descendants of the selected LI/item are currently
        //                    selectable). If it fails this check, it will dispatch a lineageCheckFailed event.
        //                    This functionality allows use to manage hierarchical roles where we dont' want to
        //                    have a user with overlapping roles (e.g., admin of a parent and child) as it might
        //                    cause confusion in other parts of the UI.
        //                    Defaults: true
        // We're going to search the select.target for a html element identified by this class
        // and if we find it, we're going to copy this node to the innerHTML of the listbox.buttonTarget.
        // This what allows us to have html formatted content displayed by the listbox when in the closed state.
        selectProcessingRules: Object,
        htmlElementClass: {type: String, default: 'item-select'},
    }

    static targets = ["button", "hidden", "list", "selected", "reset", "destroy"]
    static classes = ["selected"]

    // FIXME: figure out how we're going to handle "mass-selectable / mass-unselectable"
    connect() {
        // console.debug("listbox-controller connect")
        actionsMixin(this) // The actions support dynamically adding and removing stimulus actions

        // we can do more later if we require it
        // We carry this as an instance variable so it's not easily visible in the DOM
        // and possibly changeable by the user
        this.state = this._initialState()
        this.deferredState = null
        this.onSelectedClasses = this.hasSelectedClass ? [...this.selectedClasses] : ['opacity-50']
        if (this.hasSelectProcessingRulesValue) {
            this.selectProcessingRulesValue = this._reverseMerge()
        } else {
            this.selectProcessingRulesValue = this._defaultSelectProcessingRules
        }
        // console.debug("listbox-controller: ", this.element.id, " default state: ", this.state, " processingRules: ", this.processingRules)


        // Used to close the listbox when the user clicks outside of it.
        // Note: we not setting up the code to handle it here, we're depending on the events
        useClickOutside(this)

        // set the initial visible state
        this._processSelect(this.state)
        this._setUnselectable(this.state.target)
        if (this.state.id !== this.state.resetId && this._hideResetOnSelect) {
            this._hideReset()
        }
        // just in case we have a resetOnSelect == true, this makes sure the buttonTarget is set
        this._setButtonTarget(this.state)

        if (this.messageOnConnectValue) {
            // Because this is part of initialization, and we don't know if the other controllers are ready
            // we're going to wait just a little to make sure everyone else on the page has fully connected
            setTimeout(() => {
                this.dispatch("onConnect", {detail: this.state})
            }, 40)
        }

        // Needed so we don't attempt to deactivate orgs that are instantiated as part of the page load
        this._hierarchicalInitialized()
    }

    // Does all the heavy lifting of processing a select()
    //
    // Note: The only way we get here is if we're in immediate mode
    // or acceptDeferred() has been called.
    //
    // @param {Object} newState - the new state to be processed
    // @side-effect - updates this.state
    //              - updates this.buttonTarget
    //              - updates this.hiddenTarget
    //              - dispatches a select event
    _processSelect(newState) {
        if (this.openValue) {
            this.close()
        }

        // If we allow re-enabling of previously selected items, do so now
        if (!this._disableAutoReenable) {
            this._setSelectable(this.state.target)
        }
        // this._setUnselectable(newState.target)

        // Reset the buttonTarget
        // If configured for resetOnSelect, then simply skipping this
        // section will leave the existing initial state in place (which
        // is what we want).
        // HOWEVER, during initialization of the listbox we need this bit of code to run,
        // so we have an init flag to allow us to do that
        if (!this._resetOnSelect) {
            this._setButtonTarget(newState)
        }

        this._setHiddenTarget(newState)
    }

    // sets the button target to the name of the selected item
    _setButtonTarget(newState) {
        const htmlElementTarget = newState.target.getElementsByClassName(this.htmlElementClassValue)[0]

        if (htmlElementTarget) {
            // We have an htmlElementTarget, so we're going to copy it's innerHTML to the buttonTarget

            // Remove existing html from the buttonTarget
            while (this.buttonTarget.hasChildNodes()) {
                this.buttonTarget.removeChild(this.buttonTarget.firstChild)
            }
            this.buttonTarget.appendChild(htmlElementTarget.cloneNode(true))
        } else {
            // otherwise, just set the buttonTarget's innerHTML to the name of the selected item
            this.buttonTarget.innerHTML = newState.name
        }
    }

    // FIXME: doesn't look like we need special processing anymore
    _setHiddenTarget(newState) {
        if (this._stateReset(newState)) {
            this.hiddenTarget.dataset.id = newState.id
            this.hiddenTarget.dataset.name = newState.name
            this.hiddenTarget.value = newState.id
        } else {
            this.hiddenTarget.dataset.id = newState.id
            this.hiddenTarget.dataset.name = newState.name
            this.hiddenTarget.value = newState.id
        }
    }

    //<editor-fold desc="initialization and getters">
    //
    // Helpers for getting into an initialized state
    //

    // identifies the initial state of the listbox -- to be processed downstream
    _initialState(forcedReset = false) {
        // console.debug("listbox-controller:initialState")

        let target = null
        let state = {
            id: null,
            name: null,
            target: null,
            controllerId: this.element.id,
            selectedId: this.hasSelectedTarget ? this.selectedTarget.dataset.id : null,
            resetId: this.hasResetTarget ? this.resetTarget.dataset.id : null,
            listCount: this.listTarget.querySelectorAll("li").length,
        }

        // FIXME: if the controller is doing this initialization prior to this processing,
        //        then it needs to be done in just one place
        if (this.hasSelectedTarget && forcedReset === false) {
            target = this.selectedTarget
        } else if (this.hasResetTarget && forcedReset) {
            target = this.resetTarget
        }

        if (target) {
            state.id = target.dataset.id
            state.name = target.dataset.name
            state.target = target
        } else if (this.hasNotSelectedTextValue) {
            state.name = this.hasNotSelectedTextValue
        } else {
            console.error("listbox-controller:initialState: unexpected condition")
        }

        return state
    }

    // returns the default settings for the selectRulesValue
    get _defaultSelectProcessingRules() {
        return {
            hideResetOnSelect: false,
            processImmediate: true,
            disableAutoReenable: false,
            resetOnSelect: false,
            startSelected: [],
            hierarchical: {
                initialized: false,
                data: null,
                lineage: 'descendants',
                checkLineage: true,
            }
        }
    }

    _hierarchicalInitialized() {
        if (!this._is_hierarchical) {
            return
        }

        let data = this.selectProcessingRulesValue
        data['hierarchical']['initialized'] = true
        this.selectProcessingRulesValue = data
    }

    _stateReset(newState) {
        if (!this.hasResetTarget) {
            return false
        } else {
            return newState.id === this.resetTarget.dataset.id
        }
    }

    get _hideResetOnSelect() {
        return this.selectProcessingRulesValue.hideResetOnSelect
    }

    get _processImmediate() {
        return this.selectProcessingRulesValue.processImmediate
    }

    get _disableAutoReenable() {
        return this.selectProcessingRulesValue.disableAutoReenable
    }

    get _resetOnSelect() {
        return this.selectProcessingRulesValue.resetOnSelect
    }

    get _is_hierarchical() {
        return this._hierarchicalData !== null
    }

    get _is_hierarchical_initialized() {
        return this.selectProcessingRulesValue.hierarchical.initialized
    }

    get _hierarchicalData() {
        return this.selectProcessingRulesValue.hierarchical.data
    }

    get _is_hierarchicalLineageDescendants() {
        return this.selectProcessingRulesValue.hierarchical.lineage === 'descendants'
    }

    get _is_hierarchicalLineageAncestors() {
        return this.selectProcessingRulesValue.hierarchical.lineage === 'ancestors'
    }

    get _hierarchicalCheckLineage() {
        return this.selectProcessingRulesValue.hierarchical.checkLineage
    }

    // Implements a reverse merge of the defaultSelectProcessingRules and the custom selectProcessingRulesValue
    // So the user can just override the parts of the object they care about and can ignore the other parts.
    _reverseMerge(defaultObject = this._defaultSelectProcessingRules, userObject = this.selectProcessingRulesValue) {
        let result = {...defaultObject};

        if (userObject) {
            for (let key in userObject) {
                if (userObject.hasOwnProperty(key)) {
                    if (typeof userObject[key] === 'object' && userObject[key] !== null && defaultObject.hasOwnProperty(key)) {
                        if (typeof defaultObject[key] === 'object' && defaultObject[key] !== null) {
                            result[key] = this._reverseMerge(defaultObject[key], userObject[key]);
                        } else {
                            result[key] = userObject[key];
                        }
                    } else {
                        result[key] = userObject[key];
                    }
                }
            }
        }

        return result;
    }

    // </editor-fold">

    //
    // The outwardly facing API
    //

    // Allow an outside entity to trigger a select event
    // DOES NOT invoke any other processing other than sending the current state
    sendSelected() {
        this.dispatch("select", {detail: this.state})
    }

    // Toggles between open and close states
    // @param {Event} event - the event that triggered the toggle
    // @side-effect - toggles the listbox between open and close states which triggers dispatching
    toggle(event) {
        // FIXME: maybe set this on the action?
        //   At the very least, this would prevent the button select from triggering a form
        if (event !== undefined) {
            event.preventDefault()
        }

        this.openValue ? this.close(event) : this.open(event)
    }

    // Handles everything needed to open the dropdown list
    // @param {Event} event - the event that triggered the open
    // @side-effect - opens the listbox
    //            - dispatches an open event
    open(event) {
        this.addAction("close", "listbox:click:outside")
        this.openValue = true

        this.listTarget.classList.remove("hidden")
        this.buttonTarget.setAttribute("aria-expanded", "true")
        this.buttonTarget.focus()
        this.listTarget.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"})

        this.dispatch("open", {detail: this.state})
    }

    // Handles everything needed to close the dropdown list
    // @param {Event} event - the event that triggered the close
    // @side-effect - closes the listbox
    //              - dispatches a close event
    close(event) {
        this.removeAction("close", "listbox:click:outside")
        this.openValue = false

        this.listTarget.classList.add("hidden")
        this.buttonTarget.setAttribute("aria-expanded", "false")
        this.buttonTarget.focus()

        this.dispatch("close", {detail: this.state})
    }

    // Externally facing event available to close and reset the listbox
    // to it's starting state
    // @side-effect - sets the listbox to it's starting state
    //              - resets state to the initial state
    //              - closes the listbox
    //              - dispatches a reset event
    reset(dispatch = true) {
        this.close()
        this.state = this._initialState(true)
        this._processSelect(this.state)

        // Make any previously unselectable items selectable
        this.listTarget.querySelectorAll("li").forEach(li => {
            this._setSelectable(li)
        })

        this.dispatch("reset", {detail: this.state})
    }

    // The main entry point for the listbox to process a select event
    // @param {Event} event - the event that triggered the selection
    // @side-effect - if deferred, then sets the deferredState
    //              - if deferred, then dispatches a deferred-select event
    //              - if immediate, then calls processSelect() with the pending newState
    select(event) {
        if (event === undefined) {
            console.error("listbox-controller:select: event is undefined")
            return
        }
        event.preventDefault()

        // Hopefully, this more reflects an aggressive user re-clicking
        // than a bug or slow system response.
        if (this.deferredState !== null) {
            console.error("listbox-controller:select: deferredState is not null")
            return
        }

        const newData = {
            element: this.element,
            id: event.currentTarget.dataset.id,
            name: event.currentTarget.dataset.name,
            target: event.currentTarget
        }
        const newState = {...this.state, ...newData}

        if (this.state.id === this.state.resetId && newState.id !== this.state.resetId && this._hideResetOnSelect) {
            this._hideReset()
        }

        if (this._processImmediate) {
            this._processSelect(newState)
            this.state = newState // has to be done after _processSelect because we compare the current to new state
            const messageKind = this.hasResetTarget && this.state.target === this.resetTarget ? "reset" : "select"
            this.dispatch(messageKind, {detail: this.state})
            this._setUnselectable(newState.target)
        } else {
            this.deferredState = newState
            this.dispatch("select", {detail: this.deferredState})
        }
    }

    // Accepts the deferred selection by triggering processSelect(deferredState)
    // Once the deferredSelect has been processed, clear the deferredState
    // which is ESSENTIAL as it blocks any other selections from being processed
    acceptDeferred() {
        this._processSelect(this.deferredState)
        this.state = this.deferredState
        this.deferredState = null
    }

    // Rejects the deferred selections by clearing the deferredState and taking no other actions
    rejectDeferred() {
        this.deferredState = null
    }

    //
    // externally facing event to allow setSelectable() to be called from message or outlet
    //

    // @param {string} data_id - the data-id of the LI element to be enabled
    // @side-effect - sets the LI element as selectable
    enableByDataId(org_id) {
        const target = this.listTarget.querySelector(`[data-id="${org_id}"]`)

        if (target) {
            this._setSelectable(target)
        }
    }

    // @param {string} id - the id of the LI element to be enabled
    // @side-effect - sets the LI element as selectable
    enableTargetById(id) {
        const target = this.listTarget.getElementById(id)

        if (target) {
            this._setSelectable(target)
        }
    }

    // @param {string} data_id - the data-id of the LI element to be disabled
    // @side-effect - sets the LI element as unselectable
    disableTargetByDataId(dataId) {
        const target = this.listTarget.querySelector(`[data-id="${dataId}"]`)

        if (target) {
            this._setUnselectable(target)
        }
    }

    // @param {string} id - the id of the LI element to be disabled
    // @side-effect - sets the LI element as unselectable
    disableTargetById(id) {
        const target = this.listTarget.getElementById(id)

        if (target) {
            this._setUnselectable(target)
        }
    }

    // blur event handler to support wrap around
    // We wrap this in a setTimeout to allow the blur to have a chance to "settle"
    // before our code attempt to make decisions based on focus.
    // @param {Event} event - the event that triggered the blur
    // @side-effect - if the blur 'stepped off the list' then effects a wrap-around and
    //                sets focus to the first or last element in the list
    blur(event) {
        // Check and see if the current activeElement is inside of the listTarget (UL).
        // If it is, we moved within the list and no wrap-around required
        if (!this.openValue || this.listTarget.contains(document.activeElement)) {
            return
        }

        setTimeout(() => {

            const eventTarget = event.currentTarget || event.target

            // find all of the focusable elements in the listTarget
            const focusable = this.listTarget.querySelectorAll("li:not(.pointer-events-none)")
            const first = focusable[0]
            const last = focusable[focusable.length - 1]

            if (eventTarget === first) {
                last.focus()
            } else if (eventTarget === last) {
                first.focus()
            } else {
                console.error("listbox-controller:blur: unexpected condition")
            }
        }, 10)
    }

    //
    // Internal methods
    //

    // Hides the reset choice on selecting any value from the list
    // So the user can not go back to the reset state
    _hideReset() {
        this.resetTarget.classList.add("hidden")
    }

    // sets the state of an LI item as selectable (assumptively from unselectable)
    // @param {HTMLElement} target - the LI element to be set as selectable
    // @side-effect - sets the LI element as selectable
    _setSelectable(target) {
        let targets

        if (this._is_hierarchical && (this.hasResetTarget && target !== this.resetTarget)) {
            targets = this._hierarchicalTargets(target)
        } else {
            targets = [target]
        }

        targets.forEach((target) => {
            target.classList.remove("pointer-events-none")
            target.classList.remove(...this.onSelectedClasses)
            target.tabindex = 0
        })
    }

    // sets the state of an LI as unselectable(assumptively from selectable)
    // @param {HTMLElement} target - the LI element to be set as unselectable
    // @side-effect - sets the LI element as unselectable
    _setUnselectable(target) {
        let targets

        if (this._is_hierarchical && (this.hasResetTarget && target !== this.resetTarget)) {
            targets = this._hierarchicalTargets(target)
        } else {
            targets = [target]
        }

        targets.forEach((element) => {
            element.classList.add("pointer-events-none")
            element.classList.add(...this.onSelectedClasses)
            element.tabindex = -1

            if (this._is_hierarchical && this._is_hierarchical_initialized &&
                (this.hasResetTarget && element !== this.resetTarget) && element !== target) {
                this.dispatch("hierarchicalRemoveRole", {detail: {id: element.dataset.id}})
            }
        })
    }

    _hierarchicalTargets(target) {
        let targets = []

        if (this._is_hierarchicalLineageDescendants) {
            targets = this._descendants(target)
        } else if (this._is_hierarchicalLineageAncestors) {
            targets = this._ancestors(target)
        } else {
            console.error("listbox-controller:hierarchicalTargets: unexpected condition")
        }

        const elements = targets.map((target) => {
            return this.listTarget.querySelector(`[data-id="${target}"]`)
        })

        return elements
    }

    _descendants(target) {
        const target_data = this._hierarchicalData[target.dataset.id]

        const children = Object.keys(this._hierarchicalData)
            .filter((key) => {
                let value = this._hierarchicalData[key]
                return this.is_child_of(target_data, value)
            })
            .map((key) => {
                return key
            })

        children.push(target_data.id)
        return children
    }

    _ancestors(target) {
        const target_data = this._hierarchicalData[target.dataset.id]

        // const parents = this._hierarchicalData.map((data) => {
        const parents = Object.keys(this._hierarchicalData)
            .filter((key) => {
                let value = this._hierarchicalData[key]
                return this.is_child_of(value, target_data)
            })
            .map((key) => {
                return key
            })

        parents.push(target_data.id)
        return parents
    }

    is_child_of(target, element) {
        if (target.lft === null || element.lft === null) {
            return false
        } else {
            return target.lft < element.lft && element.lft < target.rgt
        }
    }
}
