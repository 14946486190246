import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="filter-on-page"
// Filters filterItems on the current page based on the content of event.detail.content
// Simply loops through all of the filterItemTargets and applies 'hidden' to those that do not *MATCH*
// the content of event.detail.content
//
// i.e., this is a FILTER IN, not filter out
//
// Supports an optional target of noRessults so this can show a message when everything is hidden as a result
// of the filtering
export default class extends Controller {
    static targets = ["filterItem", "noResults"]
    static values = {filterBy: String}

    connect() {
        this._showHide()
    }

    filter({detail: {content}}) {
        this.filterByValue = content.toLowerCase()
        this._showHide()
    }

    _showHide() {
        let showing = false

        this.filterItemTargets.forEach((row) => {
            if (row.dataset.filterOnPageByValue.startsWith(this.filterByValue, 0)) {
                row.classList.remove("hidden")
                showing = true
            } else {
                row.classList.add("hidden")
            }
        })

        if (this.hasNoResultsTarget) {
            if (showing) {
                this.noResultsTarget.classList.add("hidden")
            } else {
                this.noResultsTarget.classList.remove("hidden")
            }
        }
    }
}
