
export function actionsMixin(controller) {
    /**
     * Adds an action to an HTML element's dataset.
     *
     * @param {string} actionName - The name of the action.
     * @param {string=} eventName - The name of the event to associate the action with.
     * @param {HTMLElement=this.element} targetElement - The HTML element to add the action to.
     * @returns {undefined}
     *
     * NOTE: one must explicitly pass the targetElement or use addAction.call(this, ....)
     */
    const mixedInFunctions = {
        addAction: function(actionName, eventName = undefined, targetElement = undefined) {
            if (targetElement === undefined) {
                targetElement = this.element
            }
            const actions = targetElement.dataset.action?.split(" ") || []

            let newAction;
            if (eventName) {
                newAction = `${eventName}->${this.identifier}#${actionName}`
            } else {
                newAction = `${this.identifier}#${actionName}`
            }
            actions.push(newAction)

            targetElement.dataset.action = actions.join(" ")
        },

        /**
         * Removes a specific action from the dataset of an element.
         *
         * @param {string} action - The action to be removed.
         * @param {string} [event=undefined] - The event associated with the action (optional).
         * @param {HTMLElement} [targetElement=this.element] - The element from which to remove the action (optional).
         * @returns {void}
         */

        removeAction: function(action, event = undefined, targetElement = undefined) {
            if (targetElement === undefined) {
                targetElement = this.element
            }
            const actions = targetElement.dataset.action?.split(" ") || []

            const actionToRemove = actions.find(a => {
                if (a.includes("->")) {
                    const [first, aAction] = a.split("#")
                    const [aEvent, aIdentifier] = first.split("->")

                    return aAction === action && aIdentifier === this.identifier && aEvent === event
                } else {
                    const [aIdentifier, aAction] = a.split("#")

                    return aAction === action && aIdentifier === this.identifier
                }
            })

            targetElement.dataset.action = actions.filter(action => action !== actionToRemove).join(" ")
        }

    }

    Object.assign(controller, mixedInFunctions)
}

