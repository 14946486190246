import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="toggle-disable"
// Allows us to toggle the disabled state of elements
// so when we're editing or other operations, we can disable
// all of the other buttons that trigger operations on the page.
export default class extends Controller {
    static values = {wasDisabled: Boolean}

    initialize() {
        // We need tp set the wasDisabledValue to the current state of the element
        // because we're not always going to be accessing the value
        // via Stimulus, which knows how to handle a null value where as
        // our non-stimulus javascript expects this property to be present.
        //
        // We use the initialize method because the connect method is called
        // after a drag and drop operation -- during which, all buttons have
        // been disabled -- and we don't want to override the the
        // existing wasDisabledValue
        //
        if (this.element.hasAttribute('disabled')) {
            this.wasDisabledValue = true
        } else {
            this.wasDisabledValue = false
        }
    }

    toggle() {
        // console.debug(`ToggleDisableController toggle ${this.element.id}`)

        Array.from(document.getElementsByClassName('toggle-disableable')).forEach((element) => {
            if (element.dataset.toggleDisableWasDisabledValue === 'true') {
                return
            }

            if (element.hasAttribute('disabled') || element.classList.contains('disabled')) {
                this._enable(element)
            } else {
                this._disable(element)
            }
        })
    }

    enable() {
        // console.debug(`ToggleDisableController enable ${this.element.id}`)

        Array.from(document.getElementsByClassName('toggle-disableable')).forEach((element) => {
            if (element.dataset.toggleDisableWasDisabledValue === 'true') {
                return
            }

            this._enable(element)
        })
    }

    disable() {
        // console.debug(`ToggleDisableController disable ${this.element.id}`)

        Array.from(document.getElementsByClassName('toggle-disableable')).forEach((element) => {
            if (element.dataset.toggleDisableWasDisabledValue === 'true') {
                return
            }

            this._disable(element)
        })
    }

    _disable(el) {
        if (el.nodeName === 'DIV' || el.nodeName === 'IMG' || el.nodeName === 'SVG') {
            el.classList.add('disabled')
            el.classList.add('hidden')
        } else {
            el.setAttribute('disabled', 'true')
        }
    }

    _enable(el) {
        if (el.nodeName === 'DIV' || el.nodeName === 'IMG' || el.nodeName === 'SVG') {
            el.classList.remove('disabled')
            el.classList.remove('hidden')
        } else {
            el.removeAttribute('disabled')
        }
    }
}
