import {Controller} from "@hotwired/stimulus"
import Plyr from "plyr"
import {patch} from "@rails/request.js";

// Video Player Controller -- this is not a general use controller
// It's responsible for:
// 1. Initializing the video player
// 2. Updating the server with video started, video ended, and max time watched events
// 3. It manages when to display pre, post, and finished roll partials
// 4  it uses internal variables to make it a little harder for someone to hack the system
//    and skip the video
export default class extends Controller {
    static targets = [
        "preRoll", "postRoll", "finishedRoll", "video", "timeRemaining", "videoWrapper"
    ]

    static values = {
        data: Object // we send all of the data for this very specialized controller in a single data attribute
    }

    connect() {
        // console.debug("video player data: ", this.dataValue)
        this.__data = this.dataValue
        this.intervalTimer = this.debouncedInterval(this.timeEvent().send,
            this.serverUpdateInterval * 1000,
            300,
            true,
            true)

        if (!this.url) {
            console.error("video player: no url, probably missing data")
            return
        } else {
            // A little defensive programming. Remove data attributes that we wouldn't want a
            // user to alter and possibly impact the apps behavior
            // this.element.removeAttribute("data-video-player-data-value")
        }

        this.__adjustCurrentTimeAllowed = true
        this.setupVideoPlayer()
    }

    // this is called by Stimulus when the controller is removed.
    // BUT, we can also call it directly since all it does is clean up the videoPlayer
    // and any timers that might be running.
    disconnect() {
        // console.debug("video player disconnect: ")

        this.endAndSave()
    }

    endAndSave() {
        this.intervalTimer?.stop()
        this.intervalTimer = null

        if (this.videoPlayer) {
            this.videoPlayer.destroy()
            this.videoPlayer = null
        }
    }

    // debouncedInterval creates an interval timer
    // * It debounces the creation of the the timer to handle event based start/stop sequences triggered by other events
    // * It allows for calling the interval function on the leading edge (i.e., immediately, assuming it wasn't debounced)
    // * It allows for calling the interval function when stopping the timer
    debouncedInterval(func, interval = 300, debounceBy = 300, immediate = false, invokeOnStop = false) {
        let timeoutID; // used to debounce start() so if we get multiple Plyr events firing at the same time....
        let intervalID;

        return {
            start: function (...args) {
                // console.debug("debouncedInterval: start")

                clearInterval(intervalID);
                clearTimeout(timeoutID);

                // There is a bit of a race condition here.  We could end up clearing the interval, but
                // not actually starting a new one because of the debounce timer.  And then, while
                // we're waiting on the debounce timer to finish, we get a Stop() which see's the
                // intervalID and if invokeOnStop is true, it will invoke the function each time
                // Stop() is called (and in an evented system, that's out of our control).
                // So, we clear the intervalID here and stop() won't invoke the function no matter
                // how many times it's called UNTIL there is a new intervalID.
                intervalID = null

                timeoutID = setTimeout(() => {
                    intervalID = setInterval(() => {
                        func.apply(this, args);
                    }, interval);

                    // Invoke the function immediately on the leading edge
                    if (immediate) {
                        // console.debug("debouncedInterval: start -- immediate == true")
                        func.apply(this, args);
                    }

                    // console.debug("debouncedInterval: started: intervalID: ", intervalID)
                }, debounceBy)
            },
            stop: function (...args) {
                // console.debug("debouncedInterval: stop")

                const oldIntervalID = intervalID
                clearTimeout(timeoutID)
                clearInterval(intervalID);
                intervalID = null

                // Invoke the function one last time after stopping the interval
                if (oldIntervalID && invokeOnStop) {
                    // console.debug("debouncedInterval: stop: invokeOnStop == true")
                    func.apply(this, args);
                }
            }
        };
    }

    setupVideoPlayer() {
        this.videoPlayer = new Plyr(this.videoTarget, {
            controls: ['play-large', 'play', 'progress', 'rewind', 'restart', 'volume', 'mute', 'current-time',
                'fullscreen'],
            hideControls: false,
            fullscreen: {enabled: true, fallback: true, iosNative: true},
            playsinline: true,
            clickToPlay: true,
            title: this.titleValue,
            listeners: {
                // SEEKING:
                //   We need to prevent seeking forward past maxCurrentTime. Unfortunately, normal Plyr
                //   events (via on() functions) are fired too late to prevent seeking, so we register the event
                //   here, which setup regular JS listeners and are handled before Plyr listeners.
                //
                // NOTE: Despite what the docs say, when you create the listener here you get a browser,
                //       not a Plyr event
                seek: (e) => {
                    // console.debug(`seek event: ev_completed: ${this.encounterVideoCompleted}, playerTime: ${this._getTargetTime(this.videoPlayer, e)}, currentTime: ${this.currentTime}, maxTime: ${this.maxTime}, targetTime > maxTime: ${this._getTargetTime(this.videoPlayer, e) > this.maxTime}`)
                    this.__adjustCurrentTimeAllowed = false

                    if (!this.encounterVideoCompleted && this._getTargetTime(this.videoPlayer, e) > this.maxTime) {
                        // console.debug("seek event: prevented seek")

                        e.preventDefault()
                        this.videoPlayer.currentTime = this.maxTime
                        this.sendTimeEvent(this.maxTime, this.maxTime).then()
                        return false
                    }

                    // console.debug("seek event: allowed seek")
                    e.preventDefault()
                    return true // allows the seek to happen
                },
            }
        })

        //
        // Use Plyr.on / Plyr.once for all other events!!!!
        // Note: these events get passed a Plyr custom event object
        //

        // Some very *interesting* plyr behaviors:
        // On first load of the Plyr code, we only seem to get 'ready' events and they *DO* allow
        // us to set the currentTime. On subsequent (re)loads, the 'ready' event doesn't always let us
        // set the currentTime, so in that case, we have to wait for 'canplay' before
        // we can try to update Plyr.currentTime.
        //
        // On the other hand, there seem to be some cases where
        // 'ready' doesn't fire at all, but 'canplay' does.
        //
        // Once we successfully set the Plyr.currentTime, that triggers a 'timeupdate' event, which
        // triggers timeRemainingUpdate() to update the time display on the screen
        this.videoPlayer.once('ready', (e) => {
            // console.debug("on ready", this.videoPlayer.ready)
            e.detail.plyr.muted = false

            // console.debug(`on ready: eDuration: ${this.encounterVideoDuration}, currentTime: ${this.currentTime}, plyr: ${e.detail.plyr.currentTime}`)

            if (this.encounterVideoDuration - this.currentTime <= 10) {
                const origTime = e.detail.plyr.currentTime
                e.detail.plyr.currentTime = Math.max(this.currentTime - 10, 0)

                // If we were able to set the currentTime, then don't do it again in one of the other events
                if (origTime !== e.detail.plyr.currentTime) {
                    this.__adjustCurrentTimeAllowed = false
                }
            }
        })

        // can't set the currentTime until 'canplay' fires
        // If we're within 15 seconds of the end of the video at page load, we'll back up 15 seconds
        // so the user doesn't ask to play and then abruptly switch to the questions page.
        this.videoPlayer.once('canplay', (e) => {
            // console.debug("on canplay")
            e.detail.plyr.muted = false

            if (this.__adjustCurrentTimeAllowed === false) {
                // console.debug("on canPlay: hasSeeked -- preventing currentTime update")
                return
            }

            if (this.encounterVideoDuration - this.currentTime <= 10) {
                const origTime = e.detail.plyr.currentTime
                e.detail.plyr.currentTime = Math.max(this.currentTime - 10, 0)

                // If we were able to set the currentTime, then don't do it again in one of the other events
                if (origTime !== e.detail.plyr.currentTime) {
                    this.__adjustCurrentTimeAllowed = false
                }
            } else {
                e.detail.plyr.currentTime = this.currentTime
            }
        })

        // on any 'seeking', stop the timer WITH event (i.e., trigger a sendTimeEvent())
        this.videoPlayer.on('seeking', (e) => {
            // console.debug(`on seeking: plyr: ${e.detail.plyr.currentTime}, currentTime: ${this.currentTime}, maxTime: ${this.maxTime}`)

            this.intervalTimer.stop()
        })

        this.videoPlayer.on('seeked', (e) => {
            // console.debug(`on seeked: plyr: ${e.detail.plyr.currentTime}, currentTime: ${this.currentTime}, maxTime: ${this.maxTime}`)

            // We do this updateTime() because we might be in a paused state
            // and who knows the order of the 'seeked' and 'timeupdate' events?
            this.updateTime(e.detail.plyr)

            // Since 'seeking' stops the timers, if we're in the playing state, then we need to
            // send our newly seeked position to the server and restart the timers.
            // If we're not in a 'playing' state, then when the user starts the player again, we'll
            // restart the timer
            if (e.detail.plyr.playing) {
                // intervalTimer.start() will send the time event immediately
                this.intervalTimer.start()
            }
        })

        // update the time remaining and time_current values
        this.videoPlayer.on('timeupdate', (e) => {
            // console.debug("on timeupdate")

            this.updateTime(e.detail.plyr)
        })

        // On transitioning to Play, start the timer for sending time events (sends the first event immediately)
        this.videoPlayer.on('play', (e) => {
            console.debug("on play event")

            // this.updateTime(e.detail.plyr) // we don't need to do this because the timeupdate event will do it
            this.intervalTimer.start()
        })

        // On transitioning to Pause, stop the timer, and sendTimeEvent() to capture our current state
        this.videoPlayer.on('pause', (e) => {
            console.debug("on pause event")

            this.intervalTimer.stop()

            // If we paused at the very end of the video, then we might need to trigger the 'ended' event
            // since in some cases, on iPhones, we never get the 'ended' event!
            if (this.encounterVideoDuration - e.detail.plyr.currentTime < 1.0) {
                e.detail.plyr.restart()
            }
        })

        // when the video is finished, we need to notify the app
        this.videoPlayer.on('ended', (e) => {
            // console.debug("on ended event")

            if (this.videoPlayer.fullscreen.active) {
                this.videoPlayer.fullscreen.exit()
            }
            this.videoCompeted = true
            this.intervalTimer.stop()

            this.videoWrapperTarget.classList.add("hidden")
        this.postRollTarget.classList.remove("hidden") // this displays the questions text
        })
    }

    // this is needed to work around plyrs inability to give valid seek positions
    // returns the seek position as a number of seconds from start of video
    _getTargetTime(plyr, input) {
        let targetTime

        if (typeof input === "object" && (input.type === "input" || input.type === "change")) {
            targetTime = input.target.value / input.target.max * plyr.media.duration;
        } else {
            targetTime = Number(input); // We're assuming its a number
        }

        // console.debug(`_getTargetTime: ${targetTime}`)
        return targetTime
    }

    // Updates our time trackers
    updateTime(plyr) {
        this.currentTime = Math.round(plyr.currentTime)

        if (this.currentTime > this.maxTime) {
            this.maxTime = this.currentTime
        }

        // console.debug(`updateTime: ${this.currentTime}, ${this.maxTime}`)
        this.updateEncounterTimeRemaining()
    }

    // Updates the display time remaining for the video session
    updateEncounterTimeRemaining() {
        if (!this.playerInValidState()) {
            // console.debug("updateEncounterTimeRemaining: player not in valid state -- not processing updateEncounterTimeRemaining")
            return
        }

        let timeRemaining = Math.max(this.encounterBaseTime - this.currentTime, 0)
        let minutes = Math.floor(timeRemaining / 60)
        let seconds = timeRemaining % 60

        this.timeRemainingTarget.innerText = ""
        this.timeRemainingTarget.innerText = `${minutes}:${seconds.toString().padStart(2, '0')}`
    }

    timeEvent() {
        let lastCurrentTime = -9999 // this will allow the first call to pass the debounce check
        let lastNow = Date.now()

        return {
            send: () => {
                // grab the current values since there are plenty of async events flying around
                const currentTime = this.currentTime
                const maxTime = this.maxTime
                const now = Date.now()

                // With the possibility of events triggering sends in rapid succession, we need to
                // debounce the sends to prevent sending the same data multiple times.
                // We use a 2 second debounce because of impact of using rounded currentTime values
                // making small changes in currentTime able to cross the 1 second boundary.
                if (Math.abs(currentTime - lastCurrentTime) >= 2 || now - lastNow >= 1000) {
                    this.sendTimeEvent(currentTime, maxTime).then()
                    lastCurrentTime = currentTime
                    lastNow = now
                }
            }
        }
    }

    // Sends a time event to the server.
    // This allows us to track how long a user watched a video and verify it was to the end.
    // It also allows us to see if the user rewound in order to watch a section again.
    async sendTimeEvent(currentTime, maxTime) {
        // console.debug("sendTimeEvent: ", currentTime, maxTime)
        const statusContinue = 100

        // Make sure we have the data we need
        if (!this.url) {
            console.error("sendTimeEvent: no url, probably missing data")
            return
        }

        // send the time to the server
        const url = `${this.url}/time_events`
        let data = new FormData()
        data.append("time_current", currentTime)
        data.append("time_max", maxTime)

        // FIXME: this needs to be better/smarter. For example, on error (!response.ok or catch(error) notify Honeybadger
        try {
            const response = await patch(url, {body: data})
            // we use a Continue /  100 status to indicate that the video wasn't found because the
            // Viewer wasn't associated with the Encounter or because the EncounterVideo wasn't found
            // FIXME: what to do if we do get something other than OK?
            if (response.ok || response.statusCode === statusContinue) {
                // FIXME: do we want / need to do anything for OK or statusContinue?
            }
        } catch (error) {
            console.error("sendTimeEvent: error: ", error)
        }
    }

    // Start Button on the page
    // Swaps out the start page for the actual video player
    start() {
        if (!this.playerInValidState()) {
            // bad UI as we don't give the user any feedback, but it's not a fatal error
            // and one assumes the user will simply push the button again.
            return
        }
        this.preRollTarget.classList.add("hidden")
        this.videoWrapperTarget.classList.remove("hidden")

        this.videoPlayer.play().then()
    }

    //
    // The next 3 methods are all triggered by via dispatch.event -> stimulus:action
    // from the dropdown.js controller.
    //

    // Used to stop the videoPlay when the dropdown is opened
    // We also capture the 'playing' state, so when the menu is closed
    // we can put the user back to the same state: playing or paused.
    stop() {
        // console.debug("dropdown stop")
        if (this.playerInValidState() && this.videoPlayer.playing) {
            this.wasPlaying = true
            this.videoPlayer.pause()
        }
    }

    // Used by the dropdown when the user closes the dropdown.  We check to see if the player
    // was playing when the dropdown stopped the player via the this.stop().
    // If the player was playing when the dropdown was triggered, we restart the player.
    canStart() {
        // console.debug("dropdown canStart: ", this.wasPlaying)
        if (this.playerInValidState() && this.wasPlaying) {
            this.videoPlayer.play()
        }
    }

    // If the user switches videos via the dropdown, there can be a measurable delay between
    // the remaining events, plus room for end-user events.
    // This can cause issue because depending on the order of events and information can be lost.
    //
    // To prevent the various possible race conditions, we'll call endAndSave()
    // which does everything we need for an orderly shutdown AND prevents any further events.
    switchVideo() {
        // console.debug("switchVideo: ", this.videoPlayer != null)
        if (this.videoPlayer) {
            this.endAndSave()
        }
    }

    // * currentTime to 0 so we start at the beginning of the video
    // * redisplay the videoPlayer
    // * and start the player
    rewatch() {
        if (!this.playerInValidState()) {
            console.error("rewatch: player not in valid state:", this.videoPlayer, this.__data)
            return
        }

        // Reset the currentTime and the VideoPlayer.currentTime so we start at the beginning
        this.currentTime = 0
        this.videoPlayer.currentTime = 0
        this.sendTimeEvent(this.currentTime, this.maxTime).then()

        this.postRollTarget.classList.add("hidden")
        this.videoWrapperTarget.classList.remove("hidden")

        this.videoPlayer.restart()
        this.videoPlayer.play()
    }

    playerInValidState() {
        // player is ready
        if (this.videoPlayer?.ready) {
            // we have the required data
            if (this.__data && this.url) {
                return true
            }
        }

        // console.debug("playerInValidState: ", this.videoPlayer, this.__data)
        return false
    }

    get url() {
        return this.__data['url']
    }

    get encounterVideoDuration() {
        return this.__data['encounterVideoDuration']
    }

    get maxTime() {
        return this.__data['timeMax']
    }

    set maxTime(value) {
        this.__data['timeMax'] = value
    }

    get currentTime() {
        return this.__data['timeCurrent']
    }

    set currentTime(value) {
        this.__data['timeCurrent'] = value
    }

    get encounterVideoCompleted() {
        return this.__data['encounterVideoCompleted']
    }

    set encounterVideoCompleted(value) {
        this.__data['encounterVideoCompleted'] = value
    }

    get encounterBaseTime() {
        return this.__data['encounterBaseTime']
    }

    get serverUpdateInterval() {
        return this.__data['serverUpdateInterval']
    }

    get wasPlaying() {
        return this.__data['wasPlaying']
    }

    set wasPlaying(value) {
        this.__data['wasPlaying'] = value
    }

    get intervalTimer() {
        return this.__data['intervalTimer']
    }

    set intervalTimer(value) {
        this.__data['intervalTimer'] = value
    }
}
