import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    static values = {
        text: String,
        html: String,
        successContent: {type: String, default: 'COPIED!'},
        successDuration: {type: Number, default: 2000},
        fixedButtonWidth: {type: Boolean, default: true}
    }
    static classes = ['copied']

    connect() {
        this._originalContent = this.element.innerHTML
    }

    // Copies the copyValue to the clipboard (if it can).
    // * It checks browser permissions and alerts on any issue.
    // * It checks the copy value for an HTML comment that indicates the copy value is HTML
    //   and sets the clipboard type accordingly.
    // * It will fallback to to writeText if write() fails.
    // FIXME: this code has a LOT of extra code paths to work around navigator.permissions.query
    //       not being supported in Safari and Firefox.  Once they support it, we should remove.
    copy(event) {
        event.preventDefault()

        try {
            navigator.permissions.query({name: "clipboard-write", allowWithoutGesture: false})
                .then(result => {
                        if (!(result.state === 'granted' || result.state === 'prompt')) {
                            console.error(`Clipboard write permission denied: ${result.state}`)
                            this.alertUser()
                        }
                    }, () => {
                        // permissions.query promise rejected
                        // This means we need to attempt the copy anyway since we didn't get any guidance
                        // from the permissions API.
                        // console.debug('permissions.query promise rejected')
                    }
                )

            this.doCopy(event)
        } catch (err) {
            // Likely Safari or Firefox because they don't currently support permissions API.
            // Unfortunately, this is actually part of our flow of control and not really an error.
            // So we're not going to alert the user yet and actually try to do the copy anyway
            // as the code will handle an actual permissions error correctly.
            // console.debug(`Clipboard write navigator.permission.query check failed with ${err}`)

            this.doCopy(event)
        }
    }

    doCopy(event) {
        // console.debug(`In doCopy \n ${this.textValue} \n\n\n ${this.htmlValue}`)

        try {
            let clipboardItems = {}
            this.makeClipboardItems(clipboardItems)

            // Main clipboard write path -- happy path if it works
            navigator.clipboard.write([new ClipboardItem(clipboardItems)])
                .then(() => {
                        // Success!
                        this.copied()
                    },
                    () => {
                        // Failed to write using the move advanced API that supports types
                        // Falling back to writeText (text/plain only)
                        navigator.clipboard.writeText(this.copyValue)
                            .then(() => {
                                    // Sort of success
                                    console.log(`Fallback to writeText succeeded`)
                                    this.copied()
                                },
                                () => {
                                    // nothing more to do but log the failure and alert the user
                                    console.error('Clipboard writeText failed')
                                    this.alertUser()
                                })
                    })
                .catch((err) => {
                    // what happened here?
                    console.error(`Unable to write with any of the APIs: ${err}`)
                    this.alertUser()
                })
        } catch (err) {
            try {
                navigator.clipboard.writeText(this.htmlValue)
                navigator.clipboard.writeText(this.textValue)
            } catch (err) {
                // console.debug(`Unable to write with writeText: ${err}`)
                this.alertUser()
                // If we get here, we might have to specialized this function to account for the type of browser
                // console.debug(`doCopy raised an exception ${err}`)
                this.alertUser()
            }
        }
    }

    // See https://bugs.webkit.org/show_bug.cgi?id=222262 if we start to get Copy errors in Safari
    // Make the clipboard item based on the copyValue and the copyType.
    // This is here to isolate the differences between chrome and safari in regards to
    // sync Vs. async blob contruction.
    makeClipboardItems(clipboardItems) {
        this.makeTextClipboardItem(clipboardItems)
        this.makeHTMLClipboardItem(clipboardItems)
    }

    makeTextClipboardItem(clipboardItems) {
        let copyType = "text/plain"
        clipboardItems[copyType] = new Blob([this.textValue], {type: copyType})
    }

    makeHTMLClipboardItem(clipboardItems) {
        if (!this.htmlValue) {
            return
        }

        let copyType = "text/html"
        clipboardItems[copyType] = new Blob([this.htmlValue], {type: copyType})
    }

    // Consistent alerting of the user that the browser is not allowing clipboard access
    alertUser() {
        alert('Browser is not allowing clipboard access and we are unable to support Copy at this time')
    }

    // On success, swaps the copy button content with the successContentValue for successDurationValue milliseconds.
    copied() {
        if (this._timeout) {
            clearTimeout(this._timeout)
        }

        if (this.fixedButtonWidthValue) {
            this.element.style.width = `${this.element.offsetWidth}px`
        }

        this.element.innerHTML = this.successContentValue

        if (this.hasCopiedClass) {
            this.element.classList.add(...this.copiedClasses)
        }

        this._timeout = setTimeout(() => {
            this.element.innerHTML = this._originalContent
            this.element.style = ''
            if (this.hasCopiedClass) {
                this.element.classList.remove(...this.copiedClasses)
            }
        }, this.successDurationValue)
    }
}