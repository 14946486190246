import {Controller} from "@hotwired/stimulus"

// FIXME: remove role is not working now that we're trying to destroy
//        we still haven't dealt with overlapping hierarchies
//        On edit/update, if the user has a provider role, does not display Specialty

// Connects to data-controller="role-selected"
export default class extends Controller {
    static targets = ["organizations", "kind", "template", "roles", "persistedRole", "newRole"]
    static values = {
        kind: {type: Object, default: {id: null, name: null, resetId: null}},
        orgsListCount: Number,
        rolesCount: {type: Number, default: 0},
    }

    static outlets = ["listbox"]

    // Because order of connected events is Target before Controller.
    // Since the outlets are required in the targetConnected method,
    // we have to pull the outlet initialization to the initialize method
    initialize() {
        // this is just wrong -- I suspect this would be much cleaner if we split the controller into
        // a generic base and then specialized on each set of requirements: kind, admin
        this.setOutlets()
    }

    persistedRoleTargetConnected(target) {
        // console.debug("RoleSelectedController persistedRoleTargetConnected: ", target)

        // Check and see if this role is marked for destruction and we're in an edit context
        const markedForDestruction = target.getElementsByClassName("role-destroy")[0].value === "true"

        if (markedForDestruction) {
            target.classList.add("hidden")
        } else {
            const orgId = target.getElementsByClassName("role-org-id")[0].value

            this.orgsOutlet.disableTargetByDataId(orgId)
            this.rolesCountValue += 1
        }
    }

    connect() {
        // It's not a problem that we're doing it again
        // But this also ensures on a screen refresh, these values are set
        this.setOutlets()

        if (this.rolesCountValue > 0) {
            this.showOrgs()
        }
    }

    setOutlets() {
        // this is just wrong -- I suspect this would be much cleaner if we split the controller into
        // a generic base and then specialized on each set of requirements: kind, admin
        this.kindOutlet = this.listboxOutlets.find((outlet) => outlet.element.id.split("-")[1] === "kind")
        this.orgsOutlet = this.listboxOutlets.find((outlet) => outlet.element.id.split("-")[1] === "organizations")
        this.specialtyOutlet = this.listboxOutlets.find((outlet) => outlet.element.id === "specialty")
    }

    // Because we have multiple listboxes, we have to first figure out which one is sending the
    // selected event. We do this by looking at the controllerId and then calling the appropriate
    // method to process the selected item.
    selectMessage(event) {
        const controllerId = event.detail.controllerId
        const type = controllerId.split("-")[1]

        if (type === "kind") {
            this.processKindSelect(event)
        } else if (type === "organizations") {
            this.processOrgSelect(event)
        } else {
            console.error("RoleSelectedController processSelect: unknown listbox: ", controllerId)
        }
    }

    resetMessage(event) {
        const controllerId = event.detail.controllerId
        const type = controllerId.split("-")[1]

        if (type === "kind") {
            this.processKindReset(event)
        } else {
            console.error("RoleSelectedController processReset: unknown listbox: ", controllerId)
        }
    }

    // processKindSelect(event)
    // Handles the state transition required whenever the kindListBox changes values
    // * from reset to value
    // * from value to reset
    // * from valid value to valid value
    processKindSelect(event) {
        const {id, name, resetId} = event.detail
        // console.debug("RoleSelectedController processKindSelect: ", id, name, resetId)

        // Capture the before state
        const wasInResetState = this.kindWasInResetState
        const isInResetState = this.KindIsInResetState(id)

        this.kindValue = {id, name, resetId}

        if (wasInResetState && !isInResetState) {
            // console.debug("RoleSelectedController processKindSelect: from reset to value", this.orgsListCountValue)
            // from reset to value
            if (this.orgsListCountValue === 1) {
                this.requestOrgSelected()
            }
            this.showOrgs()

            if (this.kindValue.name === "Provider") {
                this.showSpecialty()
            }
        } else if (!wasInResetState && isInResetState) {
            // console.debug("RoleSelectedController processKindSelect: from value to reset")
            // from value to reset
            // This is handled via the resetMessage()
            console.error("*** RoleSelectedController processKindSelect: unexpected value to reset transition ***")
            // this.kindReset()  // FIXME this should source through kindResetMessage
        } else if (!wasInResetState && !isInResetState) {
            // console.debug("RoleSelectedController processKindSelect: from valid value to valid value")
            // from valid value to valid value
            // shouldn't need the if test here, but this allows us to have a debugging safety net
            this.updateRoleKind()

            if (this.kindValue.name === "Provider") {
                this.showSpecialty()
            } else if (this.kindValue.name === "Staff member") {
                this.hideSpecialty()
                this.specialtyOutlet.reset()
            }
        } else {
            console.error("RoleSelectedController processKindSelect: unknown state: ", event)
        }
    }

    // Returns if the last state of the kindTarget was reset
    get kindWasInResetState() {
        return this.kindValue.id === this.KindResetId
    }

    // Returns if the current state of the kindTarget is reset
    KindIsInResetState(newId) {
        return newId === this.KindResetId
    }

    // returns the resetId for the kindTarget
    get KindResetId() {
        return this.kindValue.resetId
    }

    //
    // FIXME: this hasn't been tested and it sure looks wrong -- its to handle the use case of 1 org
    //

    // request orgList current value
    requestOrgSelected() {
        // this.dispatch("emitSelectedEvent")
        // console.debug("RoleSelectedController requestOrgSelected: ", this.orgsListCountValue)
        this.orgsOutlet.sendSelected()
    }

    // Updates all existing roles to the current kindValue
    updateRoleKind() {
        Array.from(this.rolesTarget.children).forEach((role) => {
            let isPersisted = role.getElementsByClassName("role-id").length > 0

            this.roleKindAction(role)
        })
    }

    // remove targeted role
    removeRole(eventOrTarget, provisionalRemoval = false) {
        let role

        if (eventOrTarget instanceof Event) {
            role = eventOrTarget.currentTarget.closest(".role")
        } else {
            role = eventOrTarget
        }

        let id = role.getElementsByClassName("role-id")
        let org_id = role.getElementsByClassName("role-org-id")[0].value

        // if the id Element exists, then it is a persisted role
        if (id.length === 0) {
            // we can simply remove the HTML form elements and those roles won't be processed
            role.remove()
        } else {
            // since this is a persisted role, we have to set the destroy checkbox to true to remove the role
            let destroy = role.getElementsByClassName('role-destroy')[0]
            destroy.value = true
            role.classList.add("hidden")
        }

        if (!provisionalRemoval) {
            this.orgsOutlet.enableByDataId(org_id)
        }
        this.rolesCountValue -= 1

        if (this.rolesCountValue === 0) {
            if (!this.kindOutlet) {
                this.setOutlets()
            }

            this.kindOutlet.reset(true)
        }
    }

    // Removes hierarchical roles that are on sub-organizations
    // if they exist
    // id == org_id
    provisionalRemoveRole({detail: {id}}) {
        Array.from(this.rolesTarget.children).forEach((role) => {
            let org = role.querySelector('.role-org-id')
            if (id === org.value) {
                this.removeRole(role, true)
            }
        })
    }

    // When kind transitions back to the reset state (i.e., no role), then
    // * hide the orgs listbox
    // * remove all non-persisted roles from the roles list
    processKindReset(event) {
        this.kindValue = {id: this.kindValue.resetId, name: null, resetId: this.kindValue.resetId}
        this.hideOrgs()

        this.newRoleTargets.forEach((role) => {
            this.removeRole(role)
            // let orgId = role.getElementsByClassName("role-org-id")[0].value
            // this.removeRole(role)
            // this.orgsOutlet.enableByDataId(orgId)
        })

        this.persistedRoleTargets.forEach((role) => {
            this.removeRole(role)
            // let orgId = role.getElementsByClassName("role-org-id")[0].value
            // role.getElementsByClassName("role-destroy")[0].value = true
            // role.classList.add("hidden")
            // this.orgsOutlet.enableByDataId(orgId)
        })

        this.hideSpecialty()
    }

    processOrgSelect({detail: {id, name, resetId}}) {
        // console.debug("RoleSelectedController processOrgSelect: ", id, name, resetId)
        if (id !== resetId) {
            // console.debug("RoleSelectedController processOrgSelect: addRoleFromTemplate")

            // If we already had that Organization and it was destroyed, then instead of asking
            // Rails to delete followed by an insert, we'll restore the original role
            // by resetting the _destroy flag and skip adding the role via the template
            let restore = false
            this.persistedRoleTargets.forEach((role) => {
                if (role.getElementsByClassName("role-org-id")[0].value === id) {
                    role.getElementsByClassName("role-destroy")[0].value = false
                    role.classList.remove("hidden")
                    restore = true
                }
            })

            if (!restore) {
                this.addRoleFromTemplate(id, name)
            }
        }
    }

    // <editor-fold desc="onConnectMessage">

    // Trigger after a listbox has been initialized.
    // This lets us decide if this controller needs to take action based on initial states
    onConnectMessage(event) {
        const controllerId = event.detail.controllerId
        const type = controllerId.split("-")[1]

        // console.debug("RoleSelectedController processOnConnect: ", controllerId)

        if (type === "kind") {
            this.kindOnConnectMessage(event)
        } else if (type === 'organizations') {
            this.orgsOnConnectMessage(event)
        }


        // If we have less than 2 organization (0 shouldn't be possible), then we hide the rolesTarget.
        // We do this because it's enough to inform the user they have the role
        if (this.hasOrgsListCountValue && this.orgsListCountValue <= 2) {
            // console.debug("RoleSelectedController processOnConnect: requestOrgSelected()")
            this.rolesTarget.classList.add("hidden")
        }
    }

    // Whatever special case handling is needed for kindOnConnect
    kindOnConnectMessage({detail: {id, name, resetId}}) {
        // console.debug("RoleSelectedController kindOnConnectMessage: ", id, name, resetId)

        this.kindValue = {id, name, resetId}

        if (this.kindValue.id === "200") {
            this.showSpecialty()
        } else {
            this.hideSpecialty()
        }
    }

    // Whatever special case handling is needed for orgsOnConnect
    orgsOnConnectMessage({detail: {listCount}}) {
        // console.debug("RoleSelectedController orgsOnConnectMessage: ", listCount)

        this.orgsListCountValue = listCount
    }

    //</editor-fold>


    showOrgs() {
        if (this.hasOrganizationsTarget) {
            this.organizationsTarget.classList.remove("hidden")
        }
    }

    hideOrgs() {
        if (this.hasOrganizationsTarget) {
            this.organizationsTarget.classList.add("hidden")
        }
    }

    showSpecialty() {
        const target = document.getElementById("specialty")

        if (this.specialtyOutlet && target) {
            target.classList.remove("hidden")
        }
    }

    hideSpecialty() {
        const target = document.getElementById("specialty")

        if (this.specialtyOutlet && target) {
            target.classList.add("hidden")

            this.specialtyOutlet.reset()
        }
    }

    addRoleFromTemplate(id, name) {
        // FIXME: before doing this, verify that *this* role doesn't already exist

        this.rolesCountValue += 1
        // FIXME: this is not right -- we should use something else here -- time
        //  Why can't we use rolesCountValue?
        let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime()).trim()
        this.rolesTarget.insertAdjacentHTML('beforeend', content)

        let last = this.rolesTarget.lastElementChild
        last.getElementsByClassName("role-org-id")[0].value = id
        last.getElementsByClassName("role-org-name")[0].innerHTML = name
        this.roleKindAction(last)
    }

    roleKindAction(element) {
        let name = this.kindValue.name.toLowerCase()

        if (name === 'staff member') {
            name = 'staff'
        }

        element.getElementsByClassName("role-kind")[0].value = name
    }

    // Returns true if child_id is a child of parent_id based on orgsValue array elements
    isChildOf(child_id, parent_id) {
        return parent_id.lft < child_id.lft && child_id.lft < parent_id.rgt
    }
}
