import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reset"
export default class extends Controller {
  static targets = ["reset"]

  clear() {
    // console.debug("ResetController#clear")
    if (this.hasResetTarget) {
      // console.debug("Cleared")
      this.resetTarget.value = ""
    }
  }
}
