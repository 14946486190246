import {Controller} from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js"


// Connects to data-controller="update-state"
// Sends a methodValue request to the URL with the paramName and paramState as the body
// @param urlValue = the URL to send the request to
// @param methodValue = the HTTP method to use (may not be 'get')
// @param paramNameValue = the name of the parameter to send in the form data
// @param paramStateValue = the value of the parameter to send in the form data
// @param updateOnceValue = if true, only send the request once
// @param updateCountValue = the number of times the request has been sent
// @param messageValue = if true, dispatch an 'update' event when the request is sent for other controllers to listen to
//
// Note:
// Over time this should become generic
export default class extends Controller {
    static values = {
        url: String,
        method: {type: String, default: 'post'},
        paramName: String,
        paramState: String,
        updateOnce: {type: Boolean, default: true},
        updateCount: Number,
        message: Boolean,
    }

    connect() {
        if (!this.hasUrlValue) {
            console.error("update-state-controller requires a url value")
        }

        if (!this.hasParamNameValue) {
            console.error("update-state-controller requires a paramName value")
        }

        if (!this.hasParamStateValue) {
            console.error("update-state-controller requires a paramState value")
        }
    }

    async update(_event) {
        if (this.updateOnceValue && this.updateCountValue > 0) {
            return
        }

        let data = new FormData()
        data.append(this.paramNameValue, this.paramStateValue)

        const request = new FetchRequest(this.methodValue, this.urlValue, {body: data})
        const response = await request.perform()

        if (response.ok) {
            this.updateCountValue += 1
            this.dispatch('update')
        } else {
            console.error("update-state-controller: request failed: ", response)
        }
    }
}
