import {Controller} from "@hotwired/stimulus"
import {useDebounce} from "stimulus-use"

// Connects to data-controller="search-input"
// A search-input is a search field that captures a value that will be used to filter something on the page.
// This controller does not do the filtering, that is left to a FilterController of one type or another.
// This controllers job is:
// * dispatch an event that the search value has changed.
// * optionally, update the URL with the search value
// * optionally label the url search query parameter with a specific name
//
// Parameters:
// * domId: If we can't leverage bubbling to communicate with the filter controller, we can use this to
//   find the filter controller by id.
// * updateUrl: If true, update the URL with the search value
// * urlParamName: If updateUrl is true, use this as the name of the search query parameter in the URL
export default class extends Controller {
    static values = {
        domId: String,
        updateUrl: {type: Boolean, default: true},
        filterLabel: {type: String, default: 'filter-by'}
    }
    static debounces = ["inputChanged"]

    connect() {
        useDebounce(this)
    }

    // Respond to the user typing in the search field
    // Do I need debounce here?
    inputChanged() {
        // update the url, if requested (default)
        if (this.updateUrlValue) {
            const url = new URL(document.location)

            if (this.element.value.length > 0) {
                url.searchParams.set(this.filterLabelValue, this.element.value)
                window.history.replaceState({}, "", url.toString())
            } else {
                url.searchParams.delete(this.filterLabelValue)
                window.history.replaceState({}, "", url.toString())
            }
        }

        //
        // dispatch an event that the search value has changed
        //


        // if we're targeting a dom element because we can't bubble the event and reach our desired element, find it
        if (this.hasDomIdValue) {
            const target = document.getElementById(this.domIdValue)
            this.dispatch("inputChanged", {target: target, detail: {content: this.element.value}})
        } else {
            this.dispatch("inputChanged", {detail: {content: this.element.value}})
        }
    }
}
