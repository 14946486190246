import {Controller} from "@hotwired/stimulus";
import {Uppy, debugLogger} from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import AwsS3 from "@uppy/aws-s3";
import {FetchRequest} from "@rails/request.js";
import {Turbo} from "@hotwired/turbo-rails";

// <editor-fold desc="Counter">
// This is a simple counter class that allows us to wait for all uploads to complete
class Counter {
    constructor() {
        this.count = 0;
        this.resolvers = [];
    }

    increment() {
        this.count += 1;
    }

    decrement() {
        this.count -= 1;
        if (this.count === 0) {
            this.resolvers.forEach(resolve => resolve());
            this.resolvers = [];
        }
    }

    waitForZero() {
        return new Promise((resolve) => {
            if (this.count === 0) {
                resolve();
            } else {
                this.resolvers.push(resolve);
            }
        });
    }
}

// </editor-fold>

export default class extends Controller {
    static values = {
        url: String, // used for posting/putting upload data
        successUrl: String, // used for redirecting on successful upload
        allowedExtensions: Array,
        allowedMimeTypes: Array,
        maxFileSize: Number,
        minFileSize: Number
    }

    connect() {
        this.initializeUppy();
    }

    initializeUppy() {
        this.fileCounter = new Counter()

        const uppy = this.makeUppy();

        this.setUppyDashboard(uppy);
        this.setUppyS3Multipart(uppy);

        uppy.on('file-added', (file) => {
            // FIXME: this is not Stopping the Upload on the duplicate file as expected
            this.lookupShaAndFilename(uppy, file).then()
            this.fileCounter.increment()
        })

        uppy.on('dashboard:modal-closed', () => {
            setTimeout(() => {
                Turbo.visit(this.urlValue)
            }, 100)
        })

        // This one is where all the action is
        uppy.on('upload-success', this.uploadSuccess)

        uppy.on('upload-retry', (fileId) => {
            const file = uppy.getFile(fileId)

            console.debug(`Uppy#upload-retry:  fileID: ${fileId}, file: ${file}}`)

            if (file.meta.railsUnrecoverable) {
                uppy.emit('upload-error', file, new Error(`"${file.name}" has an error that will not allow it to be uploaded. Please contact technical support for assistance.`))
            } else {
                uppy.retryUpload(fileId)
            }
        })

        uppy.on('retry-all', (fileIds) => {
            let hasUnrecoverable = false
            fileIds.forEach((fileId) => {
                let file = uppy.getFile(fileId)

                if (file.meta.railsUnrecoverable) {
                    uppy.emit('upload-error', file, new Error(`"${file.name}" has an error that will not allow it to be uploaded. Remove ${file.name} in order retry recoverable files, try to recover individual files or contact technical support for assistance.`))
                    hasUnrecoverable = true
                }

                if (!hasUnrecoverable) {
                    uppy.retryAll()
                }
            })
        })

        this.uppy = uppy;
    }

    // <editor-fold desc="Setup and Disconnect">
    disconnect() {
        this.uppy.close();
    }

    // Creates our Uppy instance, with some basic settings.
    makeUppy() {
        return new Uppy({
            debug: window.Stimulus.debug,
            autoProceed: true,
            allowMultipleUploadBatches: false,
            restrictions: {
                allowedFileTypes: this.allowedExtensionsValue.concat(this.allowedMimeTypesValue),
                ...(this.hasMaxFileSizeValue ? {maxFileSize: this.maxFileSizeValue} : {}),
                ...(this.hasMinFileSizeValue ? {minFileSize: this.minFileSizeValue} : {}),
            }
        })
    }

    // Configures the Uppy Dashboard plugin.
    setUppyDashboard(uppy) {
        uppy.use(Dashboard, {
            target: "#drag-drop-area",
            width: 750,
            height: 350,
            thumbnailWidth: 280,
            showProgressDetails: true,
            proudlyDisplayPoweredByUppy: false,
            replaceTargetContent: true,
            fileManagerSelectionType: 'both',
            locale: {
                strings: {
                    browseFiles: 'upload files',
                    browseFolders: 'upload entire directories',
                }
            },
            showErrorNotification: true // Enable built-in error notifications
        })
    }

    // FIXME: setting the fileState is supposed to stop the upload, but it's not working as expected
    // Checks for the existence of this combination of SHA512 and filename in the StagedVideos table
    // and if it exists, mark this file as already uploaded.
    async lookupShaAndFilename(uppy, file) {
        const sha512 = await this.computeSHA512(file)
        uppy.setFileMeta(file.id, {sha512: sha512})

        // FIXME: this should be temporary until we can get the fileState to work as expected
        return

        const url = new URL(this.urlValue,)
        let params = new URLSearchParams(url.search)
        params.append('sha512', sha512)
        params.append('filename', file.name)

        const request = new FetchRequest('get', url.toString(), {query: params, responseKind: 'json'})

        try {
            const requestResponse = await request.perform()

            if (requestResponse.ok) {
                const json = await requestResponse.json
                if (json.status === 'found') {
                    uppy.setFileState(file.id,
                        {
                            progress: {
                                uploadStarted: true,
                                uploadCompleted: true,
                                percentage: 100,
                                bytesUploaded: file.size,
                                bytesTotal: file.size
                            },
                            isUploaded: true,
                            // error: `"${file.name} already exists in your medApprise library and will not be uploaded again.`
                        })
                    // uppy.info(`"${file.name} already exists in your medApprise library and will not be uploaded again`, 'info', 3000)
                }
            }
        } catch (error) {
            uppy.log(`Error: ${error.message}`, 'error')
        }
    }

    // Sets up Uppy to use the Uppy S3 Multipart plugin.
    // This is not really needed for anything other than our Video files, which can
    // be quite large.  This plugin allows us to upload large files in smaller chunks.
    setUppyS3Multipart(uppy) {
        uppy.use(AwsS3, {
            shouldUseMultipart(file) {
                return true;
            },
            companionUrl: '/',
        })
    }

    // </editor-fold>

    // This one is where all the action is.
    //
    // Note: on file-added, we increment the file counter.  So, we have a count of files to upload.
    // On this event, we upload the file to Rails and once that function returns, success or failure,
    // we decrement the counter.
    //
    // This way, we *KNOW* when all of the files have been processed by Rails.
    // When the counter goes to ZERO, we can then call the completeUpload function. As a defensive measure,
    // we double check for race conditions by using the waitForZero function -- it's a Promise that guarantees
    // that the counter is zero before it resolves.
    //
    // Note: We use this form of a function declaration so that we can use the 'this' keyword
    uploadSuccess = async (file, response) => {
        await this.uploadToRails(file, response)
        this.fileCounter.decrement()

        if (this.fileCounter.count === 0) {
            this.fileCounter.waitForZero().then(() => {
                this.finishedProcessing(uppy)
            })
        }
    }

    // We have to defer setting the onComplete event and even more importantly, defer emitting it.
    // Uppy processes the onComplete event as soon as it's set and it's assumed that all processing
    // is, as it's name suggests, complete.  This means we MUST have finished the uploading but also
    // any Rails post processing before can call this event (assuming my current understanding is correct).
    finishedProcessing(uppy) {
        const successful = this.uppy.getFiles().filter(file => !file.error)
        const failed = this.uppy.getFiles().filter(file => file.error)

        this.uppy.on('complete', this.completeUpload)
        uppy.emit('complete', {successful, failed})
    }

    // We use this form of a function declaration so that we can use the 'this' keyword
    completeUpload = async (response) => {
        // console.debug("Uppy#complete: ", response.successful.length, response.failed.length)

        if (response.failed.length !== 0) {
            // we have errors, so, we want to leave the dashboard open to let the end user know
            // they have issues to address
            return true;
        } else {
            const db = uppy.getPlugin("Dashboard")
            if (db.isModalOpen()) {
                db.closeModal()
            }

            uppy.close()

            // add a short delay to help the background processing finish
            setTimeout(() => {
                Turbo.visit(this.successUrlValue)
            }, 100)
        }
    }

    // Uploads to Rails and returns with a simple true / false to designate success
    // Rails attempt to send enough information for an informative error message.
    //
    // NOTE: All files that make it to the RailsUpload are treated as valid
    // (i.e., they made it to storage, it's now up to application worlflows to handle)
    async uploadToRails(file, response) {
        // console.debug("UppyUploadController#handleUploadToRails: ", file, response);

        let retryCount = 3;
        const form = this.prepareFormData(this.prepareUploadData(file, response));

        while (retryCount > 0) {
            retryCount -= 1;

            try {
                const request = new FetchRequest('post', this.urlValue, {body: form});
                const requestResponse = await request.perform()

                if (requestResponse.ok) {
                    return;
                } else if (requestResponse.statusCode === 400) {
                    let errorMessage;

                    try {
                        const json = await requestResponse.json
                        errorMessage = json.message || 'An error occurred';
                    } catch (error) {
                        this.uppy.log(`Error processing JSON response ${error.message}`)
                    }

                    this.uppy.log(`Rails upload failed: ${file.name} - status: ${requestResponse.statusCode}`);
                    this.uppy.setFileMeta(file.id, {railsUnrecoverable: true})
                    this.uppy.emit('upload-error', file, new Error(errorMessage))

                    // Inform the user of the error
                    this.uppy.info(errorMessage, 'error', 30000)

                    return
                }
            } catch (error) {
                this.uppy.log(`Rails upload failed: ${file.name} - error: ${error.message}`, 'error');
                this.uppy.setFileMeta(file.id, {railsUnrecoverable: true})
                this.uppy.emit('upload-error', file, new Error(error.message))
            }
        }
    }

    // <editor-fold desc="Rails form processing">

    // Parse the data returns by Uppy and reformat it so Rails#shrine is happy
    prepareUploadData(file, response) {
        return JSON.stringify({
            id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1],
            storage: 'cache',
            metadata: {
                size: file.size,
                filename: file.name,
                mime_type: file.type,
                sha512: file.meta.sha512
            },
            raw_upload_url: response.uploadURL
        })
    }

    // Create a html form suitable for post with the json data from prepareUploadData
    prepareFormData(uploadedFileData) {
        let data = new FormData()
        data.append("json", uploadedFileData)

        return data
    }

    // </editor-fold>

    // <editor-fold desc="Modal handling">

    openModal(e) {
        let db = this.uppy.getPlugin("Dashboard")

        if (db === null || db === undefined) {
            this.initializeUppy()
            db = this.uppy.getPlugin("Dashboard")
        }

        if (!db.isModalOpen()) {
            db.openModal()
        }
    }

    // </editor-fold>

    // Function to compute SHA512 hash
    async computeSHA512(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async function (event) {
                const arrayBuffer = event.target.result;
                const hashBuffer = await window.crypto.subtle.digest('SHA-512', arrayBuffer);
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
                resolve(hashHex);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file.data);
        });
    }
}